import { makeObservable } from 'mobx'
import moment from 'moment'
import BaseStore from './BaseStore'

export class SalarycalculationStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  setSingle(item) {
    item.start_from = moment().startOf('month').format('YYYY-MM-DD')
    item.end_to = moment().endOf('month').format('YYYY-MM-DD')
    super.setSingle(item)
  }
}

const store = new SalarycalculationStore()
export default store
