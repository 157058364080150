import * as CryptoJS from 'crypto-js'
import isArray from 'lodash/isArray'
import isFinite from 'lodash/isFinite'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import _max from 'lodash/max'
import _min from 'lodash/min'
import {
  get,
  isComputedProp,
  isObservable,
  isObservableArray,
  isObservableMap,
  remove,
  runInAction,
  set,
} from 'mobx'
import numeral from 'numeral'
import Sqids from 'sqids'
import AppStore from '../store/AppStore'
import requester from './requester'
import { API_URL, WEB_URL } from './settings'

export const webUrl = url => {
  return !url || /^((blob:)?https?:\/\/|www\.)/.test(url)
    ? url
    : WEB_URL() + url
}

export const isNotNot = value => !!value

export const isNullOrUndefined = value => isNull(value) || isUndefined(value)

export const isValidModelAndName = (model, name) =>
  isObjectLike(model) &&
  ((isString(name) && name.length > 0) || (isNumber(name) && isFinite(name)))

export function getModelValue(model, name, defaultValue) {
  if (!isValidModelAndName(model, name)) {
    return defaultValue
  }
  let value
  if (isObservableMap(model)) {
    name = name.toString()
    if (model.has(name)) {
      value = model.get(name)
    }
  } else if (isObservableArray(model) || isArray(model)) {
    name = parseInt(name)
    if (model.length > name) {
      value = model[name]
    }
  } else if (
    isObservable(model) &&
    !model.hasOwnProperty(name) &&
    !isComputedProp(model, name)
  ) {
    value = get(model, name)
  } else {
    value = model[name]
  }
  return !isUndefined(value) && !isNull(value)
    ? value
    : isUndefined(defaultValue)
    ? value
    : defaultValue
}

export function setModelValue(model, name, value, action = true) {
  if (isValidModelAndName(model, name)) {
    function assignModelValue() {
      if (isObservableMap(model)) {
        model.set(`${name}`, value)
      } else if (isObservableArray(model)) {
        const index = parseInt(name)
        if (!isNaN(index)) {
          if (model.length > index) {
            model[index] = value
          } else if (model.length === index) {
            model.push(value)
          }
        }
      } else if (isObservable(model) && isUndefined(model[name])) {
        set(model, name, value)
      } else {
        model[name] = value
      }
    }

    action ? runInAction(assignModelValue) : assignModelValue()
  }
}

export function getDeepObjectValue(model, defaultValue, ...keys) {
  const length = keys.length - 1
  for (let i = 0; i < length; i++) {
    model = getModelValue(model, keys[i])
    if (!model) return defaultValue
  }
  return getModelValue(model, keys[length], defaultValue)
}

const deepObjectValue = (value, key) => ({ [key]: value })

export function setDeepObjectValue(model, value, ...keys) {
  let deep = model
  const length = keys.length - 1
  for (let i = 0; i <= length; i++) {
    const next = getModelValue(deep, keys[i])
    if (!next || i === length) {
      value =
        i === length
          ? value
          : keys
              .slice(i + 1)
              .reverse()
              .reduce(deepObjectValue, value)
      setModelValue(deep, keys[i], value)
      break
    }
    deep = next
  }
  return model
}

export function deleteDeepObjectValue(model, ...keys) {
  model = getDeepObjectValue(model, null, ...keys.slice(0, -1))
  const name = keys[keys.length - 1]
  isValidModelAndName(model, name) && runInAction(() => remove(model, name))
}

export function toggleArrayValue(model, name, value, findIndexCallback) {
  if (isValidModelAndName(model, name)) {
    runInAction(() => {
      let arr_value = getModelValue(model, name)
      if (!isObservableArray(arr_value) && !isArray(arr_value)) {
        setModelValue(model, name, [value], false)
      } else {
        const index = findIndexCallback
          ? arr_value.findIndex(findIndexCallback(value))
          : arr_value.indexOf(value)
        if (index !== -1) {
          arr_value.splice(index, 1)
        } else {
          arr_value.push(value)
        }
      }
    })
  }
}

export function addArrayValue(model, name, value, findIndexCallback) {
  if (isValidModelAndName(model, name)) {
    runInAction(() => {
      let arr_value = getModelValue(model, name)
      if (!isObservableArray(arr_value) && !isArray(arr_value)) {
        setModelValue(model, name, [value], false)
      } else {
        const index = findIndexCallback
          ? arr_value.findIndex(findIndexCallback(value))
          : arr_value.indexOf(value)
        if (index === -1) {
          arr_value.push(value)
        }
      }
    })
  }
}

export function removeArrayValue(model, name, value, findIndexCallback) {
  if (isValidModelAndName(model, name)) {
    runInAction(() => {
      let arr_value = getModelValue(model, name)
      if (isObservableArray(arr_value) && isArray(arr_value)) {
        const index = findIndexCallback
          ? arr_value.findIndex(findIndexCallback(value))
          : arr_value.indexOf(value)
        if (index !== -1) {
          arr_value.splice(index, 1)
        }
      }
    })
  }
}

export function value2number(value) {
  if (isNumber(value)) {
    return value
  } else if (isString(value)) {
    if (value.split('.').length === 1 && value.split(',').length === 2)
      value = value.replace(',', '.')
    value = value.replace(/[\s,]/gi, '')
    if (!value) return null
    let is_valid =
      /^([\d.\-eE]+|N(aN?)?)$/.test(value) &&
      !/\..*\./.test(value) &&
      (/^-\d+e-\d*$/i.test(value) || !/-.*-/.test(value)) &&
      !/e.*e/i.test(value)
    if (!is_valid)
      throw new Error(`Can't parse value "${arguments[0]}" to number`)
    if (value === '-0') return value
    if (value === 'NaN') return NaN
    if (isNaN(value) || /(\.|\.\d*0)$/.test(value)) {
      return value
    }
    return parseFloat(value)
  }
  return null
}

const FILE_SIZES = {
  GB: 1024 * 1024 * 1024,
  MB: 1024 * 1024,
  KB: 1024,
}

export function fileSize(size) {
  if (size < FILE_SIZES.KB) {
    return size.toFixed(0) + ' B'
  } else if (size >= FILE_SIZES.GB) {
    return ((size / FILE_SIZES.GB) * 1000).toFixed(0) / 1000 + ' GB'
  } else if (size >= FILE_SIZES.MB) {
    return ((size / FILE_SIZES.MB) * 1000).toFixed(0) / 1000 + ' MB'
  } else if (size >= FILE_SIZES.KB) {
    return ((size / FILE_SIZES.KB) * 1000).toFixed(0) / 1000 + ' KB'
  }
}

export function capitalize(s) {
  return s && s[0] ? s[0].toUpperCase() + s.substring(1) : ''
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export const TRANSFER_STATUSES = {
  draft: 'Черновик',
  send: 'Отправка',
  sent: 'В пути',
  preinvalid: 'Перевод на корректировку',
  invalid: 'На корректировке',
  receive: 'Прием',
  received: 'Принят',
}

export const TRANSFER_STATUS_OPTIONS = [
  { text: 'Все', value: '' },
  { text: 'Черновик', value: 'draft' },
  { text: 'На корректировке', value: 'invalid' },
  { text: 'В пути', value: 'sent' },
  { text: 'Принят', value: 'received' },
]

export const PROVISION_STATUSES = {
  draft: 'Черновик',
  processing: 'В процессе',
  request_pay: 'Запрос. СО',
  bought: 'Куплен',
  cancel: 'Отменен',
}

export const PROVISION_STATUS_OPTIONS = [
  { text: 'Черновик', value: 'draft' },
  { text: 'В процессе', value: 'processing' },
  { text: 'Запрос. СО', value: 'request_pay' },
  { text: 'Куплен', value: 'bought' },
  { text: 'Отменен', value: 'cancel' },
]

export const PROVISION_PRIORITIES = {
  high: 'Высокий',
  medium: 'Средний',
  critical: 'Критичный',
}

export const PROVISION_PRIORITY_OPTIONS = [
  { text: 'Высокий', value: 'high' },
  { text: 'Критичный', value: 'critical' },
  { text: 'Средний', value: 'medium' },
]

export const MOVE_STATUSES = {
  draft: 'Черновик',
  send: 'Отправка',
  sent: 'В пути',
  preinvalid: 'Перевод на корректировку',
  invalid: 'На корректировке',
  receive: 'Прием',
  received: 'Принят',
}

export const MOVE_STATUS_OPTIONS = [
  { text: 'Все', value: '' },
  { text: 'Черновик', value: 'draft' },
  { text: 'На корректировке', value: 'invalid' },
  { text: 'В пути', value: 'sent' },
  { text: 'Принят', value: 'received' },
]

export const AGENTS_REPORT_STATUS_OPTIONS = [
  { text: 'Все', value: '' },
  { text: 'Новое', value: 'new' },
  { text: 'Отмена', value: 'rollback' },
  { text: 'Завершен', value: 'done' },
]

export const INVOICE_STATUSES = {
  draft: 'Черновик',
  continuous: 'Непрерывный прием',
  receive: 'Прием',
  received: 'Принят',
}

export const SALE_STATUSES = {
  new: 'Новый',
  archive: 'Архив',
}

export const mapPrices = p => {
  return {
    text: `${p.display_name}: ${p.price}`,
    value: p.name,
  }
}

const types2options = ([value, text]) => ({ value, text })

export const CONTRACTOR_TYPES = {
  ru: {
    fiz: 'Физическое лицо',
    ur: 'Юридическое лицо',
    ip: 'Индивидуальный предприниматель',
  },
  tr: {
    fiz: 'Bireysel',
    ur: 'Varlık',
    ip: 'Bireysel girişimci',
  },
}

export const CONTRACTOR_TYPES_SHORT = {
  ru: {
    fiz: 'Физ',
    ur: 'Юр',
    ip: 'ИП',
  },
  tr: {
    fiz: 'Bireysel',
    ur: 'Varlık',
    ip: 'Bireysel girişimci',
  },
}

export const CONTRACTOR_TYPES_OPTIONS = {
  ru: Object.entries(CONTRACTOR_TYPES.ru).map(types2options),
  tr: Object.entries(CONTRACTOR_TYPES.tr).map(types2options),
}

export const CONTRACTOR_TYPES_FILTER_OPTIONS = {
  ru: [{ value: '', text: 'Все' }, ...CONTRACTOR_TYPES_OPTIONS.ru],
  tr: [{ value: '', text: 'Tum' }, ...CONTRACTOR_TYPES_OPTIONS.tr],
}

export const CONTRACTOR_CATEGORIES = {
  A: 'A',
  B: 'B',
  C: 'C',
}
export const CONTRACTOR_CATEGORIES_OPTIONS = Object.entries(
  CONTRACTOR_CATEGORIES,
).map(types2options)
export const CONTRACTOR_CATEGORIES_FILTER_OPTIONS = [
  { value: '', text: 'Все' },
  ...CONTRACTOR_CATEGORIES_OPTIONS,
]
export const CONTRACTOR_DETAIL = {
  email: 'E-mail',
  phone: 'Телефон',
  instagram: 'Instagram',
  whatsApp: 'WhatsApp',
  telegram: 'Telegram',
}
export const CONTRACTOR_DETAIL_OPTIONS =
  Object.entries(CONTRACTOR_DETAIL).map(types2options)

export function checkAccess(user, action, link) {
  return user.roles.some(
    role =>
      role.admin === true ||
      (role.data[link] && role.data[link][action] === true),
  )
}

let NF_UID = 0

export class NativeFile {
  uri
  type
  name
  size
  uid

  constructor(uri, type, name, size) {
    if (!uri) {
      throw Error('Argument error. uri argument should be specified')
    }
    this.uri = uri
    this.type = type
    this.name = name ? name : uri.split('/').slice(-1)[0]
    this.size = size
    this.uid = ++NF_UID
    if (NF_UID === Math.pow(2, 31)) NF_UID = 0
  }

  toJSON() {
    return null
  }
}

export function extendObservableKeys(data, ext) {
  const entries = Object.keys(ext)
    .filter(k => isUndefined(data[k]))
    .map(k => [k, ext[k]])
  if (entries.length > 0) {
    runInAction(() => {
      entries.map(([key, value]) => {
        set(data, key, value)
      })
    })
  }
}

export function is_my_branch(ui, branch_id) {
  return ui && ui.branch_ids ? ui.branch_ids.includes(branch_id) : false
}

export const DAYS_DICT = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
}

export const DAYS_DICT_OPTIONS = [
  { value: 1, text: 'Понедельник' },
  { value: 2, text: 'Вторник' },
  { value: 3, text: 'Среда' },
  { value: 4, text: 'Четверг' },
  { value: 5, text: 'Пятница' },
  { value: 6, text: 'Суббота' },
  { value: 7, text: 'Воскресенье' },
]

export const DAYS_DICT_FILTER_OPTIONS = [
  { value: '', text: 'Все' },
  ...DAYS_DICT_OPTIONS,
]

export const HISTORY_FILTER_DICT = {
  assignees: 'Назначения',
  all: 'История',
}

export const HISTORY_FILTER_DICT_OPTIONS = [
  { value: 'assignees', text: 'Назначения' },
  { value: 'all', text: 'История' },
]

export const ROUTE_TYPES_MAP = {
  negotiation: 'Обсуждение',
  approval: 'Утверждение',
  execution: 'Исполнение',
  informative: 'Информирование',
}

export const ROUTE_TYPES_LIST = [
  { value: 'all', name: 'Все' },
  { value: 'negotiation', name: 'Обсуждение' },
  { value: 'approval', name: 'Утверждение' },
  { value: 'execution', name: 'Исполнение' },
  { value: 'informative', name: 'Информирование' },
]

export const EXPEDITION_PARAM_GROUP_OPTIONS = [
  { value: 'param', text: 'Параметры' },
  { value: 'products', text: 'Продукция' },
  { value: 'refrigerator', text: 'Холодильник' },
  { value: 'showcase', text: 'Витрина' },
]

export const EXPEDITION_PARAM_TYPE_OPTIONS = [
  { text: 'Поле текста', value: 'VARCHAR' },
  { text: 'Поле чисел', value: 'NUMERIC' },
  { text: 'Поле выбора значения', value: 'RADIO' },
]

/**
 * Returns three precision quantity value;
 * @param {number|string} value
 * @returns {numeral}
 */
export function v2n(value) {
  return numeral(isString(value) ? parseFloat(value) : value)
}

/**
 * Returns three precision quantity value;
 * @param {number|string} value
 * @returns {string}
 */
export function v2nQ(value) {
  return v2n(value).format('0,0[.]0[00]')
}

/**
 * Returns double precision percentage value;
 * @param {number|string} value
 * @returns {string}
 */
export function v2nP(value) {
  return v2n(value).format('0[.][00]')
}

/**
 * Returns double precision currency value;
 * @param {number|string} value
 * @param {boolean} with_cent
 * @returns {string}
 */
export function v2nC(value, with_cent = false) {
  return v2n(value).format('0,0' + (with_cent ? '.00[00]' : '[.][00]'))
}

export function sec2time(value) {
  const seconds = typeof value !== 'number' ? parseInt(value) : value
  let times = []

  const hour = parseInt(seconds / 3600)
  hour && times.push(`${hour} ч.`)

  const min = parseInt((seconds / 60) % 60)
  min && times.push(`${min} мин.`)

  const sec = seconds % 60
  sec && times.push(`${sec} сек.`)

  return times.join(' ')
}

export function percentOf(n1, n2) {
  const n = n1 || 0 + n2 || 0
  if (n) {
    return (100 / n) * n2
  } else if (!n2) {
    return 0
  }
  return 100
}

export function isExcelFile(type) {
  return (
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  )
}

export function saveExcelResponse(FileSaver, response, name) {
  return FileSaver.saveAs(
    new Blob([new Uint8Array(response.data)], {
      type: 'application/octet-stream',
    }),
    name,
  )
}

export async function requestExcelFile(url, params, FileSaver, name) {
  try {
    const response = await requester.post(
      url,
      params,
      {},
      { responseType: 'arraybuffer' },
    )
    if (isExcelFile(response.headers['content-type'])) {
      return saveExcelResponse(FileSaver, response.data, name)
    }
  } catch (e) {
    console.log(e)
  }
  throw new Error('Не удалось экспортировать данные')
}

export async function export2Excel(url, params, FileSaver, name) {
  return requestExcelFile(url, params, FileSaver, name)
}

class ExcelLoader {
  opener = null

  urlOpen(url, ...args) {
    if (!this.opener) {
      throw new Error("url opener didn't set")
    }
    return this.opener(url, ...args)
  }

  use(opener) {
    this.opener = opener
  }

  async requestExcel(url, data, ...args) {
    const resp = await requester.post('/excel/auth-uuid-token', data)
    return this.urlOpen(`${API_URL()}${url}?auth=${resp.data.token}`, ...args)
  }
}

export const excelLoader = new ExcelLoader()

export async function printPdf(code, printable_data) {
  const {
    data: { template },
  } = await requester.get('/report-templates/code/' + code)
  if (!template) return false
  await printPdfReport(template, { Props: printable_data })
}

export function reportFromTemplate(template, data) {
  const report = new Stimulsoft.Report.StiReport()
  if (template) {
    try {
      report.load(template)
      report.dictionary.databases.clear()
      Object.keys({ ...data }).forEach(k => {
        const data_set = new Stimulsoft.System.Data.DataSet(k)
        data_set.readJson(data[k])
        report.regData(k, k, data_set)
      })
    } catch (e) {
      console.warn(e)
    }
  }
  return report
}

export async function printPdfReport(template, data) {
  const report = reportFromTemplate(template, data)
  await new Promise(resolve =>
    report.renderAsync(() => {
      try {
        report.printToPdf()
      } finally {
        resolve()
      }
    }),
  )
}

export function toFilterParams(object) {
  return object
    ? Object.keys(object).reduce((f, k) => {
        const value = object[k]
        const type = typeof value
        if (
          !(type === 'string' && !value) &&
          !['undefined', 'object'].includes(type)
        ) {
          f[`filter[${k}]`] = value
        }
        return f
      }, {})
    : {}
}

export function imageUrl(src, width, height) {
  let query_string = []
  if (isFinite(+width)) query_string.push('width=' + (+width).toFixed(0))
  if (isFinite(+height)) query_string.push('height=' + (+height).toFixed(0))
  return !/^(blob:)?https?:\/\//.test(src)
    ? `${WEB_URL()}${src}?${query_string.join('&')}`
    : src
}

/**
 * File url for native files
 * @param {NativeFile|string} file
 * @returns {string}
 */
export function fileUrl(file) {
  return file instanceof NativeFile ? file.uri : WEB_URL() + file
}

/**
 * File unique key for native files
 * @param {NativeFile|string} file
 * @returns {string}
 */
export function fileKey(file) {
  return `${file instanceof NativeFile ? file.uid : file}`
}

/**
 * File name for native files
 * @param {NativeFile|string} file
 * @returns {string}
 */
export function fileName(file) {
  return file instanceof NativeFile ? file.name : file
}

/**
 * Image source object for native files
 * @param {NativeFile|string} file
 * @param {string} prop
 * @param {number} width
 * @param {number} height
 * @returns {{uri:string}}
 */
export function imageSource(file, prop = 'url', width, height) {
  return {
    [prop]:
      file instanceof NativeFile ? file.uri : imageUrl(file, width, height),
  }
}

export function stringToColor(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export function reduceCurrency(totals, item) {
  let total = totals.find(t => t.currency === item.currency)
  if (total) {
    total.amount += parseFloat(item.amount)
  } else {
    totals.push({ ...item, amount: parseFloat(item.amount) })
  }
  return totals
}

/**
 * Image source object for native files
 * @param {number|string} num
 * @param {number} precision
 * @returns {number}
 */
export function numRound(num, precision = 0) {
  let i = 0
  if (typeof num === 'number' && !isNaN(num)) {
    i = num
  } else if (typeof num === 'string' && !isNaN(num)) {
    i = parseFloat(num)
  }
  if (precision < 0) {
    precision = Math.abs(precision)
  }
  if (precision > 100) {
    precision = 100
  }
  if (precision % 1 !== 0) {
    precision = parseInt(precision)
  }
  return parseFloat(i.toFixed(precision))
}

/**
 * Validate production formula
 * @param {string|null|undefined} f
 * @returns {boolean|undefined}
 */
export function checkProductionFormula(f) {
  if (!f) return
  f = f.replace(/out/g, '1')
  try {
    return /^[\d+\-*/.()\s]+$/.test(f) && !isNaN(window.eval(f))
  } catch (e) {
    return false
  }
}

/**
 * Validate production output formula
 * @param {string|null|undefined} f
 * @returns {boolean|undefined}
 */
export function checkProductionOutputFormula(f) {
  if (!f) return
  const ff = f.replace(/in\d+/g, '1')
  try {
    const fff = ff.replace(/_(min|max)/g, '')
    return (
      /^[\d+\-*/.()\s,]+$/.test(fff) &&
      !isNaN(
        new Function('_min,_max', `return ${ff}`)(
          (...args) => _min(args),
          (...args) => _max(args),
        ),
      )
    )
  } catch (e) {
    return false
  }
}

export function isEmail(value) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
}

export function isPhone(value) {
  return /^0[\d]{9}$/.test(value)
}

export function isInstagram(value) {
  return /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/.test(value)
}

function columnLetterToIndex(letter) {
  let index = 0
  for (let i = 0; i < letter.length; i++) {
    index *= 26
    index += letter.charCodeAt(i) - ('A'.charCodeAt(0) - 1)
  }
  return index
}

function columnIndexToLetter(index) {
  let letter = ''
  while (index > 0) {
    let remainder = (index - 1) % 26
    letter = String.fromCharCode(65 + remainder) + letter
    index = Math.floor((index - 1) / 26)
  }
  return letter
}

export function autoFitColumns(worksheet) {
  const [firstCol, lastCol] = worksheet['!ref']?.replace(/\d/, '').split(':')
  const numRegexp = new RegExp(/\d+$/g)
  const firstColIndex = columnLetterToIndex(firstCol),
    lastColIndex = columnLetterToIndex(lastCol.replace(/\d/g, '')),
    rows = +numRegexp.exec(lastCol)[0]
  const objectMaxLength = []

  for (let i = firstColIndex; i <= lastColIndex; i++) {
    const col = columnIndexToLetter(i)
    let maxCellLength = 0

    for (let row = 1; row <= rows; row++) {
      const cell = worksheet[`${col}${row}`]
      const cellLength =
        cell && typeof cell.v !== 'undefined' && cell.v !== null
          ? cell.v.toString().length + 1
          : 0
      if (cellLength > maxCellLength) maxCellLength = cellLength
    }

    objectMaxLength.push(maxCellLength > 0 ? { width: maxCellLength } : {})
  }
  worksheet['!cols'] = objectMaxLength
}

function _calculateChecksum(detailsString) {
  const hash = CryptoJS.SHA256(detailsString)
  const hashString = CryptoJS.enc.Hex.stringify(hash).replace('-', '')
  return hashString.slice(-4)
}

function getPartQR(partId, valueLength, value) {
  if (value.length > parseInt(valueLength, 10)) {
    throw new Error('length error')
  }
  return partId + `${valueLength.padStart(2, '0')}` + value
}

function getName() {
  const url = WEB_URL()
  if (url.includes('sf.')) return 'SnackFoods'
  if (url.includes('tb.')) return 'Toiboss'
  if (url.includes('agro.')) return 'AgroKush'
  if (url.includes('akbulak.')) return 'AkBulak'
  if (url.includes('bpd.')) return 'BestProductsDistribution'
  if (url.includes('mp.')) return 'MurakePlus'
  if (url.includes('femicola.')) return 'FamiCola'
  if (url.includes('sl.')) return 'StarLogistics'
  if (url.includes('demo.')) return 'Keep24Demo'
  if (url.includes('bio.')) return 'EthnoAzyk'
  if (url.includes('office.')) return 'Keep24Office'
  return 'Keep24ERP'
}

export function generateQR(requisite, amount = 0, qr_service_id = 0) {
  const qr_version = getPartQR('00', '02', '01')
  const qr_type = getPartQR('01', '02', '11')

  const merchant_provider = 'pay24.asia'
  const qr_merchant_provider = getPartQR(
    '00',
    merchant_provider.length.toString(),
    merchant_provider,
  )

  const service_code = qr_service_id ? parseInt(qr_service_id).toString() : '00'
  const qr_service_code = getPartQR(
    '01',
    service_code.length.toString(),
    service_code,
  )

  const qr_account_id = getPartQR('10', requisite.length.toString(), requisite)

  const qr_can_edit_amount = getPartQR('12', '02', '11')
  const qr_can_edit_account_id = getPartQR('13', '02', '12')

  let payment_info =
    qr_merchant_provider +
    qr_service_code +
    qr_account_id +
    qr_can_edit_amount +
    qr_can_edit_account_id

  const qr_payment_info = getPartQR(
    '32',
    payment_info.length.toString(),
    payment_info,
  )

  const qr_mcc_type = getPartQR('52', '04', '5411')
  const qr_currency = getPartQR('53', '03', '417')

  let total = qr_version + qr_type + qr_payment_info + qr_mcc_type + qr_currency

  if (amount && amount > 0) {
    const kopecks = Math.round(parseFloat(amount.toFixed(2)) * 100).toString()
    const qr_amount = getPartQR('54', kopecks.length.toString(), kopecks)
    total += qr_amount
  }

  const service_name = getName()
  const qr_service_name = getPartQR(
    '59',
    service_name.length.toString(),
    service_name,
  )
  total += qr_service_name

  const control_sum = _calculateChecksum(total)
  const qr_control_sum = getPartQR('63', '04', control_sum)
  const ready = total + qr_control_sum

  return 'https://pay.payqr.kg/qr#' + encodeURIComponent(ready)
}

const KEEP_APP = 3
const INTERNAL_TYPE = 0

export function generateQrAccount(contractor_id, pay_with_keep) {
  const company_id = AppStore.user_info?.company_id
  if (!contractor_id || !company_id) return ''
  const sqids = new Sqids({
    alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  })
  return sqids.encode([
    KEEP_APP,
    INTERNAL_TYPE,
    company_id,
    pay_with_keep ? 0 : contractor_id,
  ])
}

export function errorMessage(error) {
  return (
    error.response?.data?.message ||
    error.data?.message ||
    error.message ||
    [error.status, error.statusText].filter(Boolean).join(' ') ||
    error.toString()
  )
}
