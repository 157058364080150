import groupBy from 'lodash/groupBy'
import {
  action,
  isObservableArray,
  makeObservable,
  observable,
  toJS,
} from 'mobx'
import { guid, setModelValue } from '../common/utils'
import BaseStore from './BaseStore'

class LineStore extends BaseStore {
  @observable edit_process = null
  @observable edit_process_path = observable.array()
  copy_tara = []

  constructor() {
    super()
    makeObservable(this)
  }

  newStage = () => ({
    key: guid(),
    name: '',
    processes: [],
  })

  @action addStage = () => {
    const { stages } = this.item
    stages && stages.push(this.newStage())
  }

  @action removeStage = stage => {
    const { stages } = this.item
    stages && stages.remove(stage)
  }

  makeProcess = process => ({
    key: guid(),
    process_id: process.id || null,
    process: process,
    branch_id: null,
  })

  @action addProcess = (stage, process) => {
    const index = stage.processes.findIndex(
      p => p.process_id && p.process_id === process.id,
    )
    index !== -1 && stage.processes.splice(index, 1)
    stage.processes.push(this.makeProcess(process))
  }

  @action createProcess = stageIndex => {
    this.setEditProcessPath(null, stageIndex, null)
  }

  @action removeProcess = (stage, process) => {
    stage.processes.remove(process)
  }

  @action removeBranch = process => {
    process.branch_id = process.branch = null
  }

  eachProcessKey = p => {
    if (!p.key) p.key = guid()
  }

  eachStageKey = s => {
    if (!s.key) s.key = guid()
    s.processes.forEach(this.eachProcessKey)
  }

  setSingle(item) {
    item.stages.forEach(this.eachStageKey)
    if (item.stages.length === 0) item.stages.push(this.newStage())
    super.setSingle(item)
  }

  onReorder = e => {
    if (!e.destination) return
    if (e.type === 'STAGE') this.reorderStage(e)
    if (e.type === 'PROCESS') this.reorderProcess(e)
  }

  @action reorderStage = e => {
    let stages = [...this.item.stages]
    const [stage] = stages.splice(e.source.index, 1)
    stage && stages.splice(e.destination.index, 0, stage)
    this.item.stages.replace(stages)
  }

  findStage = ({ droppableId }) => {
    const [, stage_key] = (droppableId || '').split(':')
    return this.item.stages.find(s => s.key === stage_key)
  }

  @action reorderProcess = e => {
    const { source, destination } = e
    let from_stage = this.findStage(source)
    let to_stage = this.findStage(destination)
    if (!from_stage || !to_stage) return
    const same_stage = from_stage.key === to_stage.key
    if (same_stage) {
      if (source.index === destination.index) return
      let processes = [...from_stage.processes]
      const [process] = processes.splice(source.index, 1)
      processes.splice(destination.index, 0, process)
      from_stage.processes.replace(processes)
    } else {
      const [process] = from_stage.processes.splice(source.index, 1)
      let processes = [...to_stage.processes]
      let index = -1
      if (process.process_id)
        index = processes.findIndex(
          p => p.process_id && p.process_id === process.process_id,
        )
      if (index !== -1) {
        processes.splice(index, 1)
        processes.splice(
          destination.index - (index < destination.index ? 1 : 0),
          0,
          process,
        )
      } else {
        processes.splice(destination.index, 0, process)
      }
      to_stage.processes.replace(processes)
    }
  }

  buildProcess() {
    return {
      name: '',
      process_type: 'standard',
      min_output_volume: null,
      max_output_volume: null,
      period: null,
      checks: [],
      ingredients: [],
      inputs: [],
      outputs: [],
      batch_prefix: null,
    }
  }

  @action setEditProcessPath = (process, stageIndex, index) => {
    this.edit_process = process ? toJS(process) : this.buildProcess()
    this.edit_process_path = [stageIndex, index]
  }

  @action submitEditProcess = () => {
    if (this.edit_process_path.length === 2 && this.edit_process) {
      const [stageIndex, index] = this.edit_process_path
      if (index !== null)
        this.item.stages[stageIndex].processes[index].process =
          this.edit_process
      else
        this.item.stages[stageIndex].processes.push(
          this.makeProcess(this.edit_process),
        )
    }
    this.clearEditProcessPath()
  }

  @action.bound clearEditProcessPath() {
    this.edit_process_path?.clear()
    this.edit_process = null
  }

  clearItem() {
    this.clearEditProcessPath()
    super.clearItem()
  }

  @action appendProcessIngredients(process, ingredients) {
    let filtered = process.ingredients.filter(
      i => ingredients.findIndex(i2 => i.ingredient_id === i2.id) === -1,
    )
    filtered.push(
      ...ingredients.map(i3 => ({ ingredient_id: i3.id, ingredient: i3 })),
    )
    process.ingredients.replace(filtered)
  }

  @action removeProcessIngredient(process, ingredient) {
    process.ingredients.remove(ingredient)
  }

  @action reorderProcessIngredient = (process, e) => {
    process.inputs.replace(this.reorderList([...process.ingredients], e))
  }

  @action appendProcessInputs(process, inputs) {
    let filtered = (process.inputs || []).filter(
      i => inputs.findIndex(i2 => i.nomenclature_id === i2.id) === -1,
    )
    filtered.push(
      ...inputs.map(i3 => ({
        nomenclature_id: i3.id,
        nomenclature: i3,
        branches: [],
        compute: null,
        round: false,
      })),
    )
    if (process.inputs) process.inputs.replace(filtered)
    else process.inputs = filtered
  }

  @action removeProcessInput(process, input) {
    process.inputs.remove(input)
  }

  @action addInputBranch(branch, input) {
    const { branches = [] } = input
    if (branches.every(b => b.id !== branch.id)) {
      branches.push(branch)
      !isObservableArray(branches) && setModelValue(input, 'branches', branches)
    }
  }

  @action removeInputBranch(branch, input) {
    input.branches.remove(branch)
  }

  @action reorderProcessInput = (process, e) => {
    process.inputs.replace(this.reorderList([...process.inputs], e))
  }

  reorderList(list, e) {
    const [item] = list.splice(e.source.index, 1)
    item && list.splice(e.destination.index, 0, item)
    return list
  }

  groupProcessOutputs(process) {
    return process.outputs ? groupBy(process.outputs, o => o.group || '') : {}
  }

  groupsSorted(grouped_outputs) {
    return Object.keys(grouped_outputs).sort()
  }

  sortOutputs(outputs) {
    return outputs
      .slice()
      .sort((a, b) => (a.group || '').localeCompare(b.group || ''))
  }

  @action appendProcessOutputs(process, group, outputs) {
    let filtered = process.outputs.filter(
      i => outputs.findIndex(i2 => i.nomenclature_id === i2.id) === -1,
    )
    filtered.push(
      ...outputs.map(i3 => ({
        nomenclature_id: i3.id,
        nomenclature: i3,
        main: false,
        branches: [],
        group,
        compute: null,
        round: false,
        directly: false,
        tara_details_value_exclude: false,
      })),
    )
    process.outputs.replace(this.sortOutputs(filtered))
  }

  @action removeProcessOutputs(process, outputs) {
    for (const output of outputs) process.outputs.remove(output)
  }

  @action removeProcessOutput(process, output) {
    process.outputs.remove(output)
  }

  @action disablePrimary(o) {
    o.primary = false
  }

  toggleProcessInputPrimary({ inputs }, { nomenclature_id }) {
    inputs
      .filter(i => i.primary && i.nomenclature_id !== nomenclature_id)
      .forEach(this.disablePrimary)
  }

  @action disableMain(o) {
    o.main = false
  }

  toggleProcessOutputMain({ outputs }, { nomenclature_id }) {
    outputs
      .filter(o => o.main && o.nomenclature_id !== nomenclature_id)
      .forEach(this.disableMain)
  }

  findOutputIndex({ droppableId, index }, process, length_as_index = false) {
    const group = droppableId.replace('output-group:', '')
    let outputs = process.outputs.filter(o =>
      group ? o.group === group : !o.group,
    )
    if (outputs[index]) {
      const { nomenclature_id } = outputs[index]
      index = process.outputs.findIndex(
        o => o.nomenclature_id === nomenclature_id,
      )
    } else if (length_as_index) {
      const { nomenclature_id } = outputs[outputs.length - 1]
      index =
        process.outputs.findIndex(o => o.nomenclature_id === nomenclature_id) +
        1
    } else {
      index = -1
    }
    return { index, group }
  }

  @action reorderProcessOutput = (process, e) => {
    if (e.type !== 'OUTPUT' || !e.destination) return
    let event = {}
    event.source = this.findOutputIndex(e.source, process)
    event.destination = this.findOutputIndex(e.destination, process, true)
    if (event.source.index === -1 || event.destination.index === -1) return
    process.outputs[event.source.index].group = event.destination.group || null
    process.outputs.replace(
      this.sortOutputs(this.reorderList([...process.outputs], event)),
    )
  }

  @action addCheckList = (process, type) => {
    process.checks.push({ type, key: guid(), name: '' })
  }

  getChecks(process, type) {
    if (!process.checks) return []
    if (!type) return process.checks.slice()
    return process.checks.filter(c => c.type === type)
  }
}

const store = new LineStore()
export default store
