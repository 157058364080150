import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import BaseStore from './BaseStore'

const makeCalendarDays = params => {
  const {
    current_date = moment().startOf('day'),
    start_date = current_date.clone().startOf('month').startOf('isoWeek'),
    end_date = current_date.clone().endOf('month').endOf('isoWeek'),
  } = params || {}
  const days = {}
  for (let day = 0; day <= end_date.diff(start_date, 'days'); day++) {
    const date = start_date.clone().add(day, 'days')
    const date_str = date.format('YYYY-MM-DD')
    days[date_str] = {
      date: date_str,
      current: date.isSame(current_date, 'day'),
      active: date.isSame(current_date, 'day'),
      toggleActive() {
        this.active = !this.active
      },
    }
  }
  return days
}

const initialDates = () => makeCalendarDays()

export class ScheduleStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  @observable schedule = observable.map({})
  @observable dates = observable.map(initialDates())
  @observable schedule_data = {
    start_time: moment.duration(9, 'hours').asSeconds(),
    end_time: moment.duration(18, 'hours').asSeconds(),
  }

  setSingle(item) {
    if ('schedule' in item) {
      this.schedule = observable.map(item.schedule)
    }
    super.setSingle(item)
  }

  async postData(pathname, item = this.item, commit = false) {
    return super.postData(
      pathname,
      {
        ...item,
        schedule: Object.fromEntries(this.schedule),
      },
      commit,
    )
  }

  get active_days() {
    return [...this.dates.keys()]
      .sort()
      .filter(date => this.dates.get(date)?.active)
  }

  get active_month() {
    const dates = [...this.dates.keys()].sort()
    const first_date = moment(dates[0])
    return first_date
      .clone()
      .startOf('isoWeek')
      .isSame(first_date.clone().endOf('isoWeek'), 'month')
      ? first_date.startOf('month')
      : first_date.endOf('isoWeek').startOf('month')
  }

  get weeks() {
    const dates = [...this.dates.keys()].sort()
    return dates.reduce(
      (weeks, date) => {
        if (weeks[weeks.length - 1].length === 7) weeks.push([date])
        else weeks[weeks.length - 1].push(date)
        return weeks
      },
      [[]],
    )
  }

  get is_business_days() {
    return [...this.dates.keys()].every(date =>
      moment(date).isoWeekday() < 6
        ? this.dates.get(date)?.active
        : !this.dates.get(date)?.active,
    )
  }

  get is_all_days() {
    return [...this.dates.keys()].every(date => this.dates.get(date)?.active)
  }

  get is_even_days() {
    return [...this.dates.keys()].every(date =>
      moment(date).isoWeekday() % 2 === 0
        ? this.dates.get(date)?.active
        : !this.dates.get(date)?.active,
    )
  }

  get is_odd_days() {
    return [...this.dates.keys()].every(date =>
      moment(date).isoWeekday() % 2 !== 0
        ? this.dates.get(date)?.active
        : !this.dates.get(date)?.active,
    )
  }

  toggleSchedule = () => {
    this.dates.forEach(day => {
      if (this.schedule.has(day.date)) !day.active && day.toggleActive()
      else if (day.active) day.toggleActive()
    })
  }

  clearActive = () => {
    this.dates.forEach(day => {
      day.active && day.toggleActive()
    })
  }

  @action
  toggleBusinessDays = () => {
    if (this.is_business_days) return this.clearActive()
    this.dates.forEach(day => {
      if (moment(day.date).isoWeekday() < 6) !day.active && day.toggleActive()
      else if (day.active) day.toggleActive()
    })
  }

  @action
  toggleAllDays = () => {
    if (this.is_all_days) return this.clearActive()
    this.dates.forEach(day => {
      !day.active && day.toggleActive()
    })
  }

  @action
  toggleEvenDays = () => {
    if (this.is_even_days) return this.clearActive()
    this.dates.forEach(day => {
      if (moment(day.date).isoWeekday() % 2 === 0)
        !day.active && day.toggleActive()
      else if (day.active) day.toggleActive()
    })
  }

  @action
  toggleOddDays = () => {
    if (this.is_odd_days) return this.clearActive()
    this.dates.forEach(day => {
      if (moment(day.date).isoWeekday() % 2 !== 0)
        !day.active && day.toggleActive()
      else if (day.active) day.toggleActive()
    })
  }

  @action
  prev = () => {
    const date = moment(this.active_month).subtract(1, 'month')
    this.dates.replace(
      makeCalendarDays({
        start_date: date.clone().startOf('month').startOf('isoWeek'),
        end_date: date.clone().endOf('month').endOf('isoWeek'),
      }),
    )
    this.toggleSchedule()
  }

  @action
  next = () => {
    const date = moment(this.active_month).add(1, 'month')
    this.dates.replace(
      makeCalendarDays({
        start_date: date.clone().startOf('month').startOf('isoWeek'),
        end_date: date.clone().endOf('month').endOf('isoWeek'),
      }),
    )
    this.toggleSchedule()
  }

  @action
  onPressDay(date) {
    this.dates.get(date)?.toggleActive()
  }

  @action
  updateSchedule = () => {
    const data = this.schedule_data
    this.schedule.merge(
      Object.fromEntries(
        this.active_days.map(date => [
          date,
          {
            date,
            data: { ...data },
          },
        ]),
      ),
    )
  }

  @action
  removeSchedule = () => {
    this.active_days.forEach(date => {
      this.schedule.has(date) && this.schedule.delete(date)
    })
  }

  @action resetSchedule() {
    this.dates = observable.map(initialDates())
    this.schedule = observable.map({})
    this.schedule_data = {
      start_time: moment.duration(9, 'hours').asSeconds(),
      end_time: moment.duration(18, 'hours').asSeconds(),
    }
  }

  @computed
  get years() {
    return Array(10)
      .fill(0)
      .map((_, i) => {
        const year = moment().year() - i
        return { value: year, text: `${year}` }
      })
  }

  @computed
  get months() {
    return Array(12)
      .fill(0)
      .map((_, month) => {
        return { value: month + 1, text: moment.months(month) }
      })
  }
}

const store = new ScheduleStore()
export default store
