import { action, computed, makeObservable, observable } from 'mobx'
import requester from '../common/requester'

export const GODEX_SETUP = {
  mm: '40',
  dark: '19',
  speed: '4',
  mode: '0',
  gap: '4',
  top: '0',
  copies: 1,
  text: {
    text: '',
    x: 100,
    y: 40,
    style: 0,
    size: 30,
    degree: 90,
    underline: false,
    strikeout: false,
  },
}

export const ZEBRA_ZQ510_SETUP = {
  x: 300,
  y: 50,
  copies: 1,
  text: {
    text: '',
    x: 200,
    y: 50,
    size: 25,
    rotate: 90,
  },
}

export const ZEBRA_ZQ521_SETUP = {
  x: 400,
  y: 50,
  copies: 1,
  text: {
    text: '',
    x: 350,
    y: 50,
    size: 25,
    rotate: 90,
  },
}

class IdentityPrintStore {
  @observable printers = observable.array()
  @observable printer = {}
  @observable refresh_index = 0
  @observable branch_printers = observable.map({})
  @observable branch_id = null

  constructor() {
    makeObservable(this)
  }

  @computed get is_printer_selected() {
    return !!this.printer.id
  }

  @computed get all_printers() {
    return [
      ...this.printers,
      ...(this.branch_printers.get(this.branch_id) || []),
    ]
  }

  @action incRefreshIndex = () => {
    this.refresh_index++
  }

  @action replacePrinters = printers => {
    this.printers.replace(printers)
    if (this.printer.id) {
      this.printer =
        this.printers.find(printer => printer.id === this.printer.id) || {}
    }
  }

  @action selectPrinter = printer => (this.printer = printer)
  @action clearSelectedPrinter = () => (this.printer = {})
  @action setBranch = branch_id => (this.branch_id = branch_id)
  @action setBranchPrinters = (branch_id, printers) =>
    this.branch_printers.set(branch_id, printers)

  fetchPrinters = async branch_id => {
    const { data } = await requester.get('/iot/printer/' + branch_id)
    return data.devices
  }

  applyPrinterSetup = printers => {
    printers.forEach(printer => {
      let { setup } = this.printers.find(p => printer.id === p.id) || {}
      let { text } = setup || {}
      if (/godex/i.test(printer.device_model)) {
        setup = { ...GODEX_SETUP, ...setup }
        text = { ...GODEX_SETUP.text, ...text }
      } else if (/zebra/i.test(printer.device_model)) {
        if (/zq521/i.test(printer.name)) {
          setup = { ...ZEBRA_ZQ521_SETUP, ...setup }
          text = { ...ZEBRA_ZQ521_SETUP.text, ...text }
        } else {
          setup = { ...ZEBRA_ZQ510_SETUP, ...setup }
          text = { ...ZEBRA_ZQ510_SETUP.text, ...text }
        }
      }
      printer.setup = { ...setup, text: { ...text } }
    })
  }
}

export default new IdentityPrintStore()
