import { action, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import { v2nQ } from '../common/utils'
import BaseStore from './BaseStore'

export class OrderStore extends BaseStore {
  @observable categories = observable.array()
  @observable product = null
  @observable productQty = null

  @observable expands = observable.map({})
  @observable preview = false

  constructor() {
    super()
    makeObservable(this)
  }

  async getCategories(products) {
    const quantities = products.reduce((o, p) => {
      o[p.nomenclature_id] = p.quantity
      return o
    }, {})
    const prices = products.reduce((o, p) => {
      o[p.nomenclature_id] = p.price
      return o
    }, {})
    const { list: categories } = await localDB.get('/categorys', {}, null)
    let { list: nomenclatures } = await localDB.get('/nomenclatures', {}, null)
    nomenclatures = nomenclatures.reduce(OrderStore.reduceNomenclatures, {})
    let { list: units } = await localDB.get('/units')
    units = units.reduce(OrderStore.reduceUnits, {})
    return categories.map(c => ({
      ...c,
      products: (nomenclatures[c.id] || []).map(n => {
        return {
          quantity: quantities[n.id] || null,
          price: prices[n.id] || null,
          nomenclature_id: n.id,
          nomenclature: { ...n, unit: units[n.unit_id] },
        }
      }),
    }))
  }

  static reduceNomenclatures(o, n) {
    if (!o[n.category_id]) o[n.category_id] = []
    o[n.category_id].push(n)
    return o
  }

  static reduceUnits(o, u) {
    o[u.id] = u
    return o
  }

  @action
  setCategories = categories => this.categories.replace(categories)

  async fetchItem(pathname) {
    let data = await localDB.get(pathname)
    let categories = []
    if (data && data.item) {
      try {
        categories = await this.getCategories(data.item?.data?.products || [])
      } catch (e) {
        console.log(e)
      }
    }
    this.setResult(data)
    this.setCategories(categories)
    return data
  }

  setSingle(item) {
    if (!item.due_date) {
      item.due_date = moment().add(1, 'day').format('YYYY-MM-DD')
    }
    if (moment(item.due_date).isSameOrBefore(moment().endOf('day'))) {
      this.setAccess(['view'])
      this.setStructure(this.structure.map(this.mapStructureReadOnly))
    }
    super.setSingle(item)
  }

  clearItem() {
    super.clearItem()
    this.categories?.clear()
    this.preview = false
  }

  categoryQuantity = c => v2nQ(c.products.reduce(OrderStore.reduceQuantity, 0))

  static reduceQuantity(t, p) {
    let q = typeof p.quantity !== 'number' ? parseFloat(p.quantity) : p.quantity
    if (isNaN(q)) return t
    return t + q
  }

  @action
  expandCategory(c) {
    this.expands.set(c.id, !this.expands.has(c.id) || !this.expands.get(c.id))
  }

  @action
  setProduct(product) {
    if (product) this.product = product
  }

  @action
  setProductQty = qty => (this.productQty = Math.abs(parseFloat(qty)))

  async postData(pathname, item = this.item) {
    let products = []
    for (let c of this.categories) {
      for (let p of c.products) {
        products.push({
          quantity: p.quantity,
          nomenclature_id: p.nomenclature_id,
          price: p.price,
        })
      }
    }
    item.data.products = products
    return super.postData(pathname, item)
  }

  @action
  togglePreviewOrder = () => (this.preview = !this.preview)

  static filterOrders(p) {
    return p.quantity && parseFloat(p.quantity) !== 0
  }
}

const store = new OrderStore()
export default store

export class XmlImportPreviewStore {
  @observable contractors = observable.array()
  @observable nomenclatures = observable.array()
  @observable users = observable.array()
  @observable sales = observable.array()
  @observable is_tomorrow = false

  constructor(props) {
    makeObservable(this)
    this.is_tomorrow = props.is_tomorrow || false
    this.contractors = props.contractors.map(contractor => {
      return { ...contractor, import: true }
    })
    this.nomenclatures = props.nomenclatures.map(nomenclature => {
      return { ...nomenclature, import: true }
    })
    this.users = props.users.map(user => {
      return { ...user, import: true }
    })
    this.sales = props.sales.map(sale => {
      return { ...sale, import: true }
    })
  }
}
