import isString from 'lodash/isString'
import _max from 'lodash/max'
import _min from 'lodash/min'
import { action, computed, observable, makeObservable } from 'mobx'
import moment from 'moment'
import bind from '../../../common/decorators/bind'
import requester from '../../../common/requester'
import AppStore from '../../../store/AppStore'
import IotStore from '../../../store/IotStore'
import TaraStore from '../../../store/TaraStore'
import { TARA_TYPE_TRUCK } from '../../editors/StockTara'
import Material from './Material'
import ScanMaterial from './ScanMaterial'
import ScanTaraMaterial from './ScanTaraMaterial'
import roundQuantity from './utils/roundQuantity'
import sumActual from './utils/sumActual'
import sumValue from './utils/sumValue'

export const TAB_INFO = 'info'
export const TAB_INPUTS = 'inputs'
export const TAB_OUTPUTS = 'outputs'

const TABS = new Set([TAB_INFO, TAB_INPUTS, TAB_OUTPUTS])

export default class States {
  @observable active_tab = TAB_INFO
  @observable show_add_input = false
  @observable show_select_add_input = false
  @observable show_add_output = false
  @observable adding_input_id = null
  @observable scan_identity = ''
  @observable scale_value = '0'
  @observable show_scanner = true
  @observable materials = observable.array()
  @observable scan_materials = observable.array()
  @observable disable_scale = false
  @observable edit_material_index = -1
  @observable edit_material_prev_value = null
  @observable output_group = null
  @observable adding_output_id = null
  @observable use_tara_details = false
  @observable branch_id = null
  @observable show_compute_materials = false
  @observable edit_tara_output_id = null
  @observable with_identity = false
  @observable request_go_back = false

  constructor(production) {
    makeObservable(this)
    this.production = production
  }

  @computed get item() {
    return this.production.item
  }

  @computed get materials_not_compute() {
    return this.item.materials_not_compute
  }

  @computed get adding_input() {
    return (
      this.materials_not_compute.find(
        m => m.nomenclature_id === this.adding_input_id,
      ) || null
    )
  }

  @computed get has_adding_input() {
    return !!this.adding_input
  }

  @computed get adding_input_index_in_materials() {
    if (this.has_adding_input) {
      return this.materials.findIndex(
        m =>
          m instanceof Material &&
          m.nomenclature_id === this.adding_input.nomenclature_id,
      )
    }
    return -1
  }

  @computed get materials_length() {
    return this.materials_not_compute.length
  }

  @computed get materials_total_value() {
    return roundQuantity(this.materials.reduce(sumValue, 0))
  }

  @computed get can_send_materials() {
    return !this.materials_not_compute.some(
      m =>
        this.materials.every(m2 => m.nomenclature_id !== m2.nomenclature_id) &&
        this.item.materials_filtered.every(
          m2 => m.nomenclature_id !== m2.nomenclature_id,
        ),
    )
  }

  @computed get can_submit_scale_value() {
    if (this.show_add_input) {
      if (this.has_adding_input && this.adding_input.quantity !== null) {
        return (
          Math.abs(this.adding_input.remained_quantity - this.scale_value) <
          this.adding_input.quantity * 0.05
        )
      }
    }
    return true
  }

  @computed get scale_device_defined() {
    const { branch_id } = this.item
    return IotStore.branch_devices.has(branch_id)
  }

  @computed get edit_material() {
    const i = this.edit_material_index
    return (i > -1 && this.materials.length > i && this.materials[i]) || null
  }

  @computed get has_edit_material() {
    return !!this.edit_material
  }

  @computed get can_select_group() {
    return this.item.groups.length > 1
  }

  @computed get selected_group() {
    const groups = this.item.groups
    if (this.can_select_group) {
      const g = this.output_group
      return g !== null && groups.includes(g) ? g : null
    }
    return groups.length > 0 ? groups[0] : null
  }

  @computed get has_selected_group() {
    return this.selected_group !== null
  }

  @computed get selectable_adding_outputs() {
    return (
      (this.has_selected_group &&
        this.item.grouped_outputs[this.selected_group]) ||
      []
    )
  }

  @computed get can_select_adding_output() {
    return this.selectable_adding_outputs.length > 1
  }

  @computed get adding_output() {
    const outputs = this.selectable_adding_outputs
    if (outputs.length > 1) {
      const id = this.adding_output_id
      return (
        (id !== null && outputs.find(o => o.nomenclature_id === id)) || null
      )
    }
    return outputs.length > 0 ? outputs[0] : null
  }

  @computed get has_adding_output() {
    return !!this.adding_output
  }

  @computed get branch() {
    return (
      this.adding_output.branches.find(b => b.id === this.branch_id) || null
    )
  }

  @computed get can_get_tara_details() {
    return this.show_add_output && this.scan_identity && this.use_tara_details
  }

  @computed get tara_details() {
    if (!this.can_get_tara_details) return {}
    const details = TaraStore.getDetails(this.scan_identity) || {}
    return this.adding_output.tara_details_value_exclude
      ? { ...details, value: 0 }
      : details
  }

  @computed get has_tara_details() {
    return !!this.tara_details.details
  }

  @computed get has_tara_output() {
    return this.adding_output.tara && this.adding_output.tara.length > 0
  }

  @computed get output_value() {
    let v = (this.scale_value && parseFloat(this.scale_value)) || 0
    if (this.has_tara_details) {
      const { details, value } = this.tara_details
      if (details.value) {
        v -= details.value
      }
      if (value) {
        v -= value
      }
      if (
        details.tara_type === TARA_TYPE_TRUCK &&
        details.sticks_count &&
        details.sticks_weight
      ) {
        v -= details.sticks_count * details.sticks_weight
      }
    }

    if (this.has_tara_output) {
      v -= this.weight_tara_output
    }
    return (v && roundQuantity(v)) || 0
  }

  @computed get weight_tara_output() {
    if (!this.has_adding_output) return 0
    const { tara } = this.adding_output
    if (!tara || tara.length === 0) return 0

    let total_value = tara
      .filter(f => f.checked)
      .reduce((total, t) => {
        let weight = (t.weight && parseFloat(t.weight)) || 0
        let count = (t.count && parseFloat(t.count)) || 0

        return total + weight * count
      }, 0)

    if (total_value < 0) return 0

    return total_value
  }

  @computed get active_view() {
    if (this.has_edit_tara_output) {
      return VIEW_EDIT_TARA_OUTPUT
    }
    if (this.has_edit_material) {
      return VIEW_EDIT_MATERIAL
    }
    if (this.scan_materials.length > 0) {
      return VIEW_SCAN_MATERIALS
    }
    if (this.has_adding_input) {
      if (this.show_select_add_input && this.materials_length > 1) {
        return VIEW_SELECT_ADD_INPUT
      }
      if (this.show_add_input) {
        return VIEW_ADD_INPUT
      }
    }
    if (this.show_add_output) {
      if (this.show_compute_materials) {
        return VIEW_COMPUTE_ADD_INPUT
      }
      if (this.has_adding_output) {
        return VIEW_ADD_OUTPUT
      }
      if (this.has_selected_group) {
        return VIEW_SELECT_ADD_OUTPUT
      }
      return VIEW_SELECT_ADD_OUTPUT_GROUP
    }
    return VIEW_TABS
  }

  @computed get can_add_input() {
    return (
      this.production.store.can_edit &&
      ['pending', 'running'].includes(this.production.item.status) &&
      this.active_tab !== TAB_OUTPUTS
    )
  }

  @computed get can_add_output() {
    return (
      this.production.store.can_edit &&
      this.production.item.status === 'running' &&
      this.active_tab !== TAB_INPUTS
    )
  }

  @computed get edit_tara_output() {
    const id = this.edit_tara_output_id
    return (
      (id !== null && this.item.outputs.find(o => o.nomenclature_id === id)) ||
      null
    )
  }

  @computed get has_edit_tara_output() {
    return !!this.edit_tara_output
  }

  @computed get output_print_texts() {
    return [
      this.item.identity,
      this.adding_output?.name,
      moment().format('YYYY-MM-DD HH:mm'),
      `${this.output_value} ${this.adding_output?.unit}`,
      this.branch?.name || this.item.branch,
    ].filter(Boolean)
  }

  @computed get output_qr_code() {
    return this.adding_output
      ? [
          this.item.identity,
          this.branch?.id || this.item.branch_id,
          this.adding_output?.nomenclature_id,
          this.output_value,
        ].join('/')
      : this.item.identity
  }

  @computed get check_output() {
    if (this.item.outputs.some(o => o.main)) {
      const unit_id = this.item.outputs[0].unit_id
      return (
        this.item.outputs.every(o => o.unit_id === unit_id) &&
        this.item.materials.every(m => m.unit_id === unit_id)
      )
    }
    return false
  }

  @action setActiveTab(tab) {
    this.active_tab = (TABS.has(tab) && tab) || TAB_INFO
  }

  @action.bound showAddInput() {
    this.toggleScaleIfDeviceDefined()

    const materials = this.materials_not_compute
    if (this.adding_input_id && !this.has_adding_input) {
      this.adding_input_id = null
    }
    if (!this.adding_input_id && materials.length > 0) {
      let material =
        materials.find(m => m.primary) || materials.find(m => !m.actual)
      if (!material) {
        material = materials[0]
      }
      this.adding_input_id = material.nomenclature_id
    }

    if (this.disable_scale && this.has_adding_input) {
      const i = this.adding_input_index_in_materials
      const value =
        i !== -1 ? this.materials[i].value : this.adding_input.remained_quantity
      this.setScaleValue(this.materials_total_value + value)
    } else {
      this.setScaleValue(0)
    }

    this.show_add_input = true
  }

  @action.bound hideAddInput() {
    this.toggleScaleIfDeviceDefined()
    if (this.disable_scale) {
      this.setScaleValue(0)
    }
    this.show_add_input = false
    this.adding_input_id = null
  }

  @action.bound showAddOutput() {
    this.show_add_output = true
    this.setOutDefaults()
  }

  @action setOutDefaults() {
    if (this.has_adding_output) {
      this.toggleScaleIfDeviceDefined()
      if (this.adding_output.branches.length === 1) {
        this.branch_id = this.adding_output.branches[0].id
      } else if (this.branch_id !== null) {
        this.branch_id = null
      }
      const unit_id = this.adding_output.unit_id
      if (this.disable_scale) {
        const is_formula = !!this.adding_output.compute
        const can_sum =
          this.item.materials_filtered.every(m => m.unit_id === unit_id) &&
          this.item.outputs_filtered.every(o => o.unit_id === unit_id)
        if (is_formula) {
          let compute = isString(this.adding_output.compute)
            ? this.adding_output.compute
            : ''
          const materials = this.item.materials_filtered.sort(
            (a, b) => b.nomenclature_id - a.nomenclature_id,
          )
          for (const m of materials) {
            compute = compute.replace(
              new RegExp(`in${m.nomenclature_id}`, 'g'),
              m.actual,
            )
          }
          compute = compute.replace(/in\d+/g, '0')
          let value = 0
          try {
            value = new Function('_min,_max', `return ${compute}`)(
              (...args) => _min(args),
              (...args) => _max(args),
            )
          } catch {}
          if (value < 0 || !isFinite(value)) value = 0
          value = value - this.item.outputs_filtered.reduce(sumActual, 0)
          if (value < 0 || !isFinite(value)) value = 0
          this.setScaleValue(value)
        } else if (can_sum) {
          this.setScaleValue(
            this.item.materials_filtered.reduce(sumActual, 0) -
              this.item.outputs_filtered.reduce(sumActual, 0),
          )
        } else {
          this.setScaleValue(0)
        }
      }
      this.showScanner()
    }
  }

  @action.bound hideAddOutput() {
    this.show_add_output = false
  }

  @action.bound showSelectAddInput() {
    this.show_select_add_input = true
  }

  @action.bound hideSelectAddInput() {
    this.show_select_add_input = false
  }

  @action.bound selectAddInput(m) {
    this.adding_input_id = m.nomenclature_id
    this.showAddInput()
    this.hideSelectAddInput()
  }

  @action.bound setScaleValue(v) {
    v = (v && parseFloat(v)) || 0
    if (this.show_add_input) {
      v -= this.materials_total_value
    }
    if (this.disable_scale && !v) {
      this.scale_value = ''
    } else {
      this.scale_value = (v > 0 && roundQuantity(v)) || 0
    }
  }

  @action.bound hideScanner() {
    this.show_scanner = false
  }

  @action.bound onReadScanner({ data }) {
    this.scan_identity = data || ''
    this.hideScanner()
    if (this.show_add_input && this.scan_identity) {
      this.searchScanIdentity()
    } else if (
      this.show_add_output &&
      this.scan_identity &&
      !this.use_tara_details
    ) {
      this.use_tara_details = TaraStore.identity_details.has(this.scan_identity)
    }
    this.can_get_tara_details && TaraStore.fetchDetails(this.scan_identity)
  }

  @action.bound showScanner() {
    this.show_scanner = true
  }

  @action.bound toggleScanner() {
    this.show_scanner = !this.show_scanner
  }

  @bind
  async searchScanIdentity() {
    if (!this.scan_identity) return
    const [identity, branch_id, nomenclature_id] = this.scan_identity.split('/')
    const {
      data: { materials },
    } = await requester.get('/production/tara-materials/' + identity)

    if (materials.length === 0) {
      AppStore.showWarning('Нет результатов')
      return []
    }

    materials.forEach(m => (m.origin = m.value))

    const reducer = (scan_materials, material) => {
      const in_tara = materials.filter(
        scanned_material =>
          scanned_material.tara_identity &&
          scanned_material.nomenclature_id === material.nomenclature_id,
      )

      if (in_tara.length > 0) {
        scan_materials.push(new ScanTaraMaterial(in_tara, material))
      }

      const in_stock = materials.filter(
        scanned_material =>
          !scanned_material.tara_identity &&
          scanned_material.nomenclature_id === material.nomenclature_id,
      )

      Object.values(
        in_stock.reduce((branches, scanned_material) => {
          if (!branches[scanned_material.branch_id]) {
            branches[scanned_material.branch_id] = []
          }

          branches[scanned_material.branch_id].push(scanned_material)
          return branches
        }, {}),
      ).forEach(branch_materials => {
        if (branch_id && nomenclature_id) {
          if (
            parseInt(branch_id) === branch_materials[0].branch_id &&
            parseInt(nomenclature_id) === branch_materials[0].nomenclature_id
          )
            scan_materials.push(new ScanMaterial(branch_materials, material))
        } else {
          scan_materials.push(new ScanMaterial(branch_materials, material))
        }
      })

      return scan_materials
    }

    this.replaceScanMaterials(this.materials_not_compute.reduce(reducer, []))
  }

  @action replaceScanMaterials(materials) {
    const split_arr = this.scan_identity.split('/')
    const [identity, _, __, value] = split_arr
    this.scan_identity = identity
    materials.length === 0 && AppStore.showWarning('Нет результатов')
    this.scan_materials.replace(materials)
    if (split_arr.length === 4 && this.scan_materials.length === 1) {
      this.submitScanMaterials(true, value)
    }
  }

  @action.bound clearScanMaterials() {
    this.scan_materials.clear()
  }

  @action removeScanMaterial(m) {
    this.scan_materials.remove(m)
  }

  @computed get is_scan_overflow_value() {
    return Array.from(
      new Set(this.scan_materials.map(m => m.nomenclature_id)),
    ).some(nomenclature_id => {
      const adding_input = this.materials_not_compute.find(
        m => m.nomenclature_id === nomenclature_id,
      )
      const quantity = parseFloat(adding_input.quantity) || 0
      if (quantity > 0) {
        let value = this.scan_materials.reduce((value, material) => {
          if (material.nomenclature_id === nomenclature_id) {
            const scan_value = parseFloat(material.value) || 0
            return scan_value > 0 ? value + scan_value : value
          }
          return value
        }, 0)
        value += parseFloat(adding_input.actual) || 0
        return quantity * 2 < value
      }
      return false
    })
  }

  @action.bound submitScanMaterials(is_one = false, value) {
    if (this.is_scan_overflow_value) {
      AppStore.showWarning('Слишком большое количество')
      return
    }
    this.scan_materials.forEach(m => m.makeEdited())
    this.materials.push(...this.scan_materials)

    if (is_one === true) {
      let id = `${this.scan_materials[0].branch_id}-${this.scan_materials[0].product_id}-${this.scan_materials[0].nomenclature_id}`
      let index = this.materials.findIndex(
        i => `${i.branch_id}-${i.product_id}-${i.nomenclature_id}` === id,
      )
      if (index !== -1) {
        value = parseFloat(value)
        let v =
          value && value <= this.materials[index].origin
            ? value
            : this.materials[index].origin
        this.materials[index].value = parseFloat(v) || 0
      }
    }
    this.clearScanMaterials()
  }

  @action editMaterial(m) {
    this.toggleScaleIfDeviceDefined()
    const value = m.value
    if (!this.disable_scale) {
      m.value = 0
    }
    this.setScaleValue(this.materials_total_value + value)
    if (m instanceof Material) {
      this.selectAddInput(m)
    } else {
      this.edit_material_prev_value = value
      this.edit_material_index = this.materials.findIndex(
        m2 => m2.key === m.key,
      )
    }
  }

  @action.bound hideEditMaterial() {
    if (this.has_edit_material && this.edit_material_prev_value !== null) {
      this.edit_material.value = this.edit_material_prev_value
    }
    this.edit_material_index = -1
    this.showAddInput()
  }

  @action removeMaterial(m) {
    this.materials.remove(m)
  }

  @action.bound enableScale() {
    this.disable_scale = false
  }

  @action.bound disableScale() {
    this.disable_scale = true
  }

  @action.bound toggleScale() {
    this.disable_scale = !this.disable_scale
  }

  @action.bound submitScaleValue() {
    let value = (this.scale_value && parseFloat(this.scale_value)) || 0
    const quantity = this.adding_input?.quantity
    if (quantity > 0) {
      value += parseFloat(this.adding_input.actual) || 0
      if (quantity * 2 < value) {
        AppStore.showWarning('Слишком большое количество')
        return
      }
    }
    if (this.has_edit_material) {
      this.edit_material.value = value
      this.edit_material_prev_value = null
      this.enableScaleIfHasDevice()
      this.hideEditMaterial()
    } else {
      if (this.has_adding_input) {
        this.adding_input.value = value
        const i = this.adding_input_index_in_materials
        ;(i !== -1 && this.materials.splice(i, 1, this.adding_input)) ||
          this.materials.push(this.adding_input)
      }
      this.enableScaleIfHasDevice()
      if (this.show_add_input) {
        this.selectNextAddInput()
      }
    }
  }

  // disable if device not found;
  toggleScaleIfDeviceDefined() {
    if (this.scale_device_defined) {
      const device = IotStore.getDevice(this.item.branch_id)
      !device?.ip_port ? this.disableScale() : this.enableScale()
    }
  }

  enableScaleIfHasDevice() {
    this.disable_scale &&
      this.scale_device_defined &&
      IotStore.getDevice(this.item.branch_id)?.ip_port &&
      this.enableScale()
  }

  @action selectNextAddInput() {
    const material = this.materials_not_compute.find(
      m =>
        !m.actual &&
        this.materials.findIndex(
          m2 => m2.nomenclature_id === m.nomenclature_id,
        ) === -1,
    )
    material && this.selectAddInput(material)
  }

  @bind
  async sendMaterials() {
    const materials = this.materials.reduce((to_send, m) => {
      if (m instanceof Material) {
        to_send.push(m.toJSON())
      } else if (m instanceof ScanMaterial) {
        to_send.push(...m.toJSON())
      }
      return to_send
    }, [])

    let [production_id, stage, process] = this.production.id.split('-')
    const action = this.item.status === 'pending' ? 'start' : 'add-materials'
    await requester.post('/production/' + action, {
      production_id: parseInt(production_id),
      stage,
      process,
      parameters: { materials },
    })
    await this.production.store.fetchProduction()
    this.onReadScanner({ data: '' })
    this.clearMaterials()
    if (this.with_identity) {
      this.hideAddInput()
      this.with_identity = false
      this.request_go_back = true
    } else if (this.item.materials_filtered.length === this.materials_length) {
      this.hideAddInput()
      this.showAddOutput()
    }
  }

  @action clearMaterials() {
    this.materials.clear()
  }

  @action selectOutputGroup(g) {
    this.output_group = g
    this.setOutDefaults()
  }

  @action.bound hideSelectAddOutput() {
    this.output_group = null
    if (!this.can_select_group) {
      this.hideAddOutput()
    }
  }

  @action selectAddOutput(o) {
    this.adding_output_id = o.nomenclature_id
    this.setOutDefaults()
  }

  @action.bound hideAddingOutput() {
    this.adding_output_id = null
    if (!this.can_select_adding_output) {
      this.hideSelectAddOutput()
    }
  }

  @action.bound checkTaraDetails() {
    this.show_add_output &&
      !this.use_tara_details &&
      this.scan_identity &&
      TaraStore.identity_details.has(this.scan_identity) &&
      TaraStore.identity_details.delete(this.scan_identity)
  }

  @action.bound disableUseTaraDetails() {
    this.use_tara_details = false
  }

  @bind sendAddOutput() {
    return this.sendOutput('add-outputs')
  }

  @bind stopProcess() {
    if (
      this.item.materials_compute.length > 0 &&
      !this.show_compute_materials
    ) {
      this.showComputeMaterials()
    } else {
      return this.sendOutput('stop')
    }
  }

  async sendOutput(path) {
    if (!this.has_adding_output) {
      throw new Error('')
    }
    const compute_materials = this.item.materials_compute.map(m => m.toJSON())
    if (this.adding_output.branches.length > 1 && !this.branch_id) {
      AppStore.showWarning('Выберите отдел')
      throw new Error('Выберите отдел')
    }

    const [production_id, stage, process] = this.production.id.split('-')
    await requester.post('/production/' + path, {
      production_id: parseInt(production_id),
      stage,
      process,
      remain_quantity: 0,
      parameters: {
        output: {
          ...this.adding_output.toJSON(),
          value: this.output_value,
          branch_id: this.branch_id,
        },
        check_output: this.check_output,
      },
      compute_materials,
      tara_identity: this.scan_identity || null,
    })

    try {
      await this.production.store.fetchProduction()
    } catch (e) {}
    if (this.scan_identity) this.onReadScanner({ data: '' })
    this.setScaleValue(0)
    this.hideComputeMaterials()
    this.hideAddingOutput()
    if (path === 'stop') {
      this.hideSelectAddOutput()
      this.hideAddOutput()
      this.setActiveTab(TAB_OUTPUTS)
    }
  }

  @action.bound showComputeMaterials() {
    this.show_compute_materials = true
  }

  @action.bound hideComputeMaterials() {
    this.show_compute_materials = false
  }

  @action.bound editTaraOutput(o) {
    this.hideTaraOutput()
    this.showScanner()
    this.edit_tara_output_id = o.nomenclature_id
    if (this.edit_tara_output.branches.length > 0) {
      this.branch_id = this.edit_tara_output.branches[0].id
    }
  }

  @action.bound hideTaraOutput() {
    this.onReadScanner({ data: '' })
    this.setScaleValue(0)
    this.branch_id = null
    this.edit_tara_output_id = null
  }

  @bind
  async submitTaraOutput() {
    if (
      !this.has_edit_tara_output ||
      !this.scan_identity ||
      !this.scale_value ||
      !parseFloat(this.scale_value)
    ) {
      return
    }
    const { nomenclature_id, branches = [] } = this.edit_tara_output
    const filter =
      branches.length > 0 ? o => o.branch_id === this.branch_id : () => true
    let value = parseFloat(this.scale_value)
    let materials = this.edit_tara_output.actual_outputs
      .filter(filter)
      .map(o => {
        let quantity = (o.actual && parseFloat(o.actual)) || 0
        quantity = quantity > value ? value : quantity
        value -= quantity
        if (value < 0) value = 0
        return {
          product_id: o.pid,
          branch_id: this.branch_id || null,
          nomenclature_id,
          quantity,
        }
      })
    await requester.post('/production/tara-put', {
      tara_identity: this.scan_identity,
      materials,
      branch_id: this.item.branch_id,
    })
    AppStore.showSuccess('Занесен в тару')
    this.hideTaraOutput()
    await this.production.store.fetchProduction()
  }

  @action withIdentity(with_identity) {
    this.with_identity = !!with_identity
    if (this.can_add_input) {
      this.showAddInput()
      this.onReadScanner({ data: with_identity })
    }
  }

  @action.bound goBack() {
    if (this.request_go_back) {
      this.request_go_back = false
    }
    if (this.has_edit_material) {
      this.edit_material_index = -1
    } else if (this.scan_materials.length > 0) {
      this.clearScanMaterials()
    } else if (this.show_select_add_input) {
      this.hideSelectAddInput()
    } else if (this.show_add_input) {
      this.hideAddInput()
    } else if (this.show_add_output) {
      if (this.show_compute_materials) {
        this.hideComputeMaterials()
      } else if (this.has_adding_output) {
        this.hideAddingOutput()
      } else if (this.has_selected_group) {
        this.hideSelectAddOutput()
      } else {
        this.hideAddOutput()
      }
    } else {
      return false
    }
  }
}

export const VIEW_TABS = 'TABS'
export const VIEW_ADD_INPUT = 'ADD_INPUT'
export const VIEW_EDIT_MATERIAL = 'EDIT_MATERIAL'
export const VIEW_SELECT_ADD_INPUT = 'SELECT_ADD_INPUT'
export const VIEW_SCAN_MATERIALS = 'SCAN_MATERIALS'
export const VIEW_SELECT_ADD_OUTPUT_GROUP = 'SELECT_ADD_OUTPUT_GROUP'
export const VIEW_SELECT_ADD_OUTPUT = 'SELECT_ADD_OUTPUT'
export const VIEW_ADD_OUTPUT = 'ADD_OUTPUT'
export const VIEW_COMPUTE_ADD_INPUT = 'COMPUTE_ADD_INPUT'
export const VIEW_EDIT_TARA_OUTPUT = 'EDIT_TARA_OUTPUT'
