import autobind from 'autobind-decorator'
import classNames from 'classnames'
import isObjectLike from 'lodash/isObjectLike'
import { runInAction } from 'mobx'
import { inject, observer, PropTypes as MobxTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { FaClose, FaExternalLink } from 'react-icons/lib/fa'
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap'
import NumInputBase from '../../common/components/ui/NumInputBase'
import {
  getModelValue,
  guid,
  setModelValue,
  toggleArrayValue,
  webUrl,
} from '../../common/utils'

export { FormLabel } from './FormLabel'

@inject('store')
@observer
export class FormInput extends Component {
  static propTypes = {
    model: PropTypes.oneOfType([
      MobxTypes.objectOrObservableObject,
      MobxTypes.arrayOrObservableArray,
      MobxTypes.observableMap,
    ]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputName: PropTypes.string,
    readOnly: PropTypes.bool,
  }

  onChange = e => {
    const { model, name, onChange, type } = this.props
    const value = e.target.value
    setModelValue(
      model,
      name,
      type !== 'number' ? value : value !== '' ? value : null,
    )
    onChange && onChange(value)
  }

  get value() {
    const { model, name, value: defaultValue } = this.props
    const value = getModelValue(model, name, defaultValue || '')
    return typeof value === 'string'
      ? value
      : value === null || typeof value === 'undefined'
      ? ''
      : value.toString()
  }

  render() {
    const {
      model,
      name,
      readOnly,
      value,
      inputName,
      onChange,
      store,
      ...props
    } = this.props
    return (
      <Input
        {...props}
        name={inputName}
        value={this.value}
        onChange={this.onChange}
        readOnly={readOnly || store.AppStore.isBusy}
      />
    )
  }
}

@inject('store')
@observer
export class MultipleCheckbox extends Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: MobxTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func,
  }

  @autobind
  _onChange(value) {
    let { model, name, onChange } = this.props
    toggleArrayValue(model, name, value)
    if (onChange) {
      onChange(getModelValue(model, name, []))
    }
  }

  render() {
    let { model, name, options, disabled, store, ...rest } = this.props
    let { AppStore } = store
    let v = getModelValue(model, name, [])
    return (
      <FormGroup check>
        {options.map(option => (
          <div className={'checkbox'} key={option.value}>
            <Label check>
              <ValueInput
                {...rest}
                type='checkbox'
                disabled={AppStore.isBusy || disabled}
                onChange={this._onChange}
                checked={v.includes(option.value)}
                value={option.value}
              />
              {option.text}
            </Label>
          </div>
        ))}
      </FormGroup>
    )
  }
}

class ValueInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }

  _onChange = () => {
    this.props.onChange(this.props.value)
  }

  render() {
    const { value, onChange, ...rest } = this.props
    return <Input {...rest} value={value} onChange={this._onChange} />
  }
}

@inject('store')
@observer
export class AutoButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    type: 'button',
  }

  render() {
    let { store, disabled, children, ...props } = this.props
    let { AppStore } = store
    return (
      <Button {...props} disabled={disabled || AppStore.isBusy}>
        {children}
      </Button>
    )
  }
}

export class FileButton extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    multiple: false,
    accept: 'image/jpeg, image/jpg, image/png, application/pdf, .docx, .xlsx',
    disabled: false,
  }

  @autobind
  onDrop(files) {
    let { onSelect, multiple } = this.props
    if (onSelect) {
      onSelect(multiple ? files : files[0])
    }
  }

  render() {
    let { multiple, accept, children, ...props } = this.props
    return (
      <AutoButton {...props}>
        <Dropzone
          multiple={multiple || false}
          style={{ height: '100%' }}
          onDrop={this.onDrop}
          accept={accept}
        >
          {children}
        </Dropzone>
      </AutoButton>
    )
  }
}

@inject('store')
@observer
export class FileInput extends Component {
  static propTypes = {
    model: PropTypes.object,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    accept: PropTypes.string,
    canClear: PropTypes.bool,
    readOnly: PropTypes.bool,
    base64: PropTypes.bool,
  }

  static defaultProps = {
    multiple: false,
    accept: 'image/*, application/pdf, .docx, .xlsx',
    canClear: false,
    readOnly: false,
    base64: false,
  }

  static XLSX =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

  @autobind
  onDrop(files) {
    let { model, name, onChange, multiple, readOnly, base64 } = this.props
    if (readOnly) {
      return
    }
    if (base64 && files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]
        runInAction(() => {
          model[name] = base64String
        })
        onChange && onChange(base64String)
      }
      reader.readAsDataURL(file)
    } else {
      runInAction(() => {
        if (model && name) {
          if (multiple) {
            model[name] = [...(model[name] || []), ...files]
          } else {
            model[name] = files[0] || null
          }
        }
      })
      onChange && onChange(files)
    }
  }

  @autobind
  clear() {
    let { model, name, multiple, canClear, onChange, readOnly } = this.props
    if (!readOnly && canClear) {
      runInAction(() => {
        model[name] = multiple ? [] : null
      })
      onChange && onChange(model[name])
    }
  }

  get files() {
    const { multiple, model, name } = this.props
    if (model && name)
      return (multiple ? model[name] || [] : [model[name]]).filter(f => !!f)
    return []
  }

  @autobind
  preview() {
    for (const f of this.files) {
      if (f instanceof File || isObjectLike(f)) {
        window.open(f.preview)
      } else {
        window.open(webUrl(f))
      }
    }
  }

  render() {
    const { store, multiple, accept, canClear, readOnly } = this.props
    const control = (
      <Dropzone
        multiple={!!multiple}
        className='form-control'
        onDrop={this.onDrop}
        accept={accept}
        disabled={readOnly || store.AppStore.isBusy}
      >
        {this.files.length === 0
          ? 'Выберите файл(ы)'
          : this.files
              .map(f => {
                return f instanceof File
                  ? f.name
                  : (isObjectLike(f) ? f.preview : f).split('/').slice(-1)
              })
              .join(', ')}
      </Dropzone>
    )
    return this.files.length > 0 ? (
      <InputGroup>
        {control}
        <InputGroupAddon addonType='append'>
          {!readOnly && canClear && (
            <AutoButton onClick={this.clear}>
              <FaClose />
            </AutoButton>
          )}
          <AutoButton onClick={this.preview}>
            <FaExternalLink />
          </AutoButton>
        </InputGroupAddon>
      </InputGroup>
    ) : (
      control
    )
  }
}

@inject('store')
@observer
export class FormRadio extends Component {
  static propTypes = {
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    disabled: PropTypes.bool,
    canClear: PropTypes.bool,
    boolean: PropTypes.bool,
    inline: PropTypes.bool,
    onChange: PropTypes.func,
    options: MobxTypes.arrayOrObservableArray,
  }

  static defaultProps = {
    valueKey: 'value',
    labelKey: 'text',
    disabled: false,
    canClear: true,
    boolean: false,
    inline: false,
  }

  constructor(props) {
    super(props)
    this.state = { name: guid(), item: null }
    this.onChange = this.onChange.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { model, name } = nextProps
    if (model && (name || name === 0)) {
      let { item } = prevState
      if (item !== model[name]) {
        return { ...prevState, item: model[name] }
      }
    }
    return prevState
  }

  onChange(value) {
    if (typeof value === 'undefined') value = null
    let { model, name, onChange, boolean } = this.props
    let item = this.state.item
    if (model && (name || name === 0)) {
      item = boolean ? value === 'true' : value
      setModelValue(model, name, item)
    }
    this.setState({ item })
    onChange && onChange(item)
  }

  render() {
    let {
      model,
      name,
      options,
      disabled,
      valueKey,
      labelKey,
      canClear,
      inline,
      className,
    } = this.props
    if (!valueKey) valueKey = 'value'
    if (!labelKey) labelKey = 'text'
    let value = getModelValue(model, name, this.state.item)
    if (!options) options = []
    let { AppStore } = this.props.store
    return (
      <FormGroup
        tag='fieldset'
        className={classNames({ 'radio-inline': inline }, className)}
      >
        {canClear ? (
          <FormGroup check>
            <Label className='text-muted' check>
              <RadioInput
                name={this.state.name}
                checked={typeof value === 'undefined' || value === null}
                value={null}
                onClick={this.onChange}
                disabled={AppStore.isBusy || disabled}
              />
              Не выбран
            </Label>
          </FormGroup>
        ) : null}
        {options.map((o, i) => (
          <FormGroup key={i} check>
            <Label check>
              <RadioInput
                key={o[valueKey]}
                name={this.state.name}
                checked={o[valueKey] === value}
                value={o[valueKey]}
                onClick={this.onChange}
                disabled={AppStore.isBusy || disabled}
              />
              {o[labelKey]}
            </Label>
          </FormGroup>
        ))}
      </FormGroup>
    )
  }
}

class RadioInput extends React.Component {
  onClick = () => {
    this.props.onClick(this.props.value)
  }

  render() {
    const { onChange, value, ...props } = this.props
    return (
      <Input
        {...props}
        value={value === null ? '' : value}
        onClick={this.onClick}
        onChange={onChange || (() => false)}
        type='radio'
      />
    )
  }
}

@inject('store')
@observer
export class SearchInput extends Component {
  static propTypes = {
    model: PropTypes.oneOfType([
      MobxTypes.objectOrObservableObject,
      MobxTypes.arrayOrObservableArray,
      MobxTypes.observableMap,
    ]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoFocus: PropTypes.bool,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    inputName: PropTypes.string,
  }

  static defaultProps = {
    autoFocus: true,
  }

  onKeyPress = e => {
    const { onSubmit } = this.props
    if (e.charCode === 13 && onSubmit) {
      e.preventDefault()
      onSubmit()
    }
  }

  onTextChange = e => {
    e.preventDefault()
    const { model, name } = this.props
    const value = e.target.value
    setModelValue(model, name, value)
  }

  render() {
    const { store, model, name, readOnly, inputName, onSubmit, ...props } =
      this.props
    if (onSubmit) props.onKeyPress = this.onKeyPress
    return (
      <Input
        {...props}
        name={inputName}
        value={getModelValue(model, name, '')}
        onChange={this.onTextChange}
        readOnly={props.disabled || readOnly}
      />
    )
  }
}

@observer
export class NumInput extends NumInputBase {
  static propTypes = {
    ...NumInputBase.propTypes,
    autoFocus: PropTypes.bool,
  }

  render() {
    const { model, name, ...props } = this.props
    return <Input {...props} value={this.value} onChange={this.onChange} />
  }
}
