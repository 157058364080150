import isUndefined from 'lodash/isUndefined'
import * as mobx from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import requester from '../../common/requester'
import { guid, is_my_branch } from '../../common/utils'
import AppStore from '../AppStore'
import BaseStore from '../BaseStore'
import { DocumentStore } from '../DocumentStore'

export class ProductInvoiceStore extends DocumentStore {
  @observable dict_branches = observable.map({})
  @observable edit_product = null
  @observable search_identity = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get is_loading() {
    return AppStore.isBusy
  }

  @computed get has_product() {
    const { data } = this.item
    return data.products?.length > 0 && !!data.products[0].nomenclature_id
  }

  @action setLoadingCounter(increase = true) {
    AppStore.setBusyState(increase)
  }

  @computed get has_tara_quantity() {
    return this.item.data?.products.some(p => p.tara_quantity) || false
  }

  @computed get has_identity() {
    return this.item.data?.products.some(p => p.identity) || false
  }

  @computed get has_tara_identity() {
    return this.item.data?.products.some(p => p.tara_identity) || false
  }

  @computed get total_total_quantity() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.total_quantity)
          return isFinite(q) ? a + q : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get total_tara_send_quantity() {
    return (
      this.item.data?.tara_send_items
        .reduce((a, p) => {
          const q = parseFloat(p.count)
          return isFinite(q) ? a + q : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get total_quantity() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.quantity)
          return isFinite(q) ? a + q : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get tara_total_quantity() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.tara_quantity)
          return isFinite(q) ? a + q : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get tara_total_weight() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.tara_quantity)
          const w = parseFloat(p.tara_weight)
          return isFinite(q) && isFinite(w) ? a + q * w : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get deadhead_total_weight() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const w = parseFloat(p.deadhead_weight)
          return isFinite(w) ? a + w : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get pallet_total_weight() {
    return (
      this.item.data?.pallet_items
        .reduce((a, p) => {
          const w = parseFloat(p.weight)
          return isFinite(w) ? a + w : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get death_total_weight() {
    return (
      this.item.data?.death_items
        .reduce((a, p) => {
          const w = parseFloat(p.death_weight_add_1 || '0')
          const w1 = parseFloat(p.death_weight_add_2 || '0')
          const w2 = parseFloat(p.death_weight_add_3 || '0')
          return a + w + w1 + w2
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get total_weight() {
    return (
      parseFloat(this.total_quantity) - parseFloat(this.pallet_total_weight)
    )
  }

  setData(data, count) {
    for (let item of data) {
      if (
        item.branch_id &&
        item.branch &&
        !this.dict_branches.has(`${item.branch_id}`)
      ) {
        this.dict_branches.set(`${item.branch_id}`, item.branch)
      }
    }
    super.setData(data, count)
  }

  clearItems() {
    super.clearItems()
    this.dict_branches?.clear()
  }

  clearItem() {
    super.clearItem()
    this.edit_product = null
    this.prices = null
  }

  getBranchName(branch_id) {
    if (!branch_id) {
      return ''
    } else if (this.dict_branches.has(`${branch_id}`)) {
      return this.dict_branches.get(`${branch_id}`).name
    } else if (this.addQueue('/branchs/' + branch_id)) {
      requester.get('/branchs/' + branch_id).then(this.onBranchLoad)
    }
    return ''
  }

  onBranchLoad = data => {
    data && this.setBranch(data.item)
  }

  @action
  setBranch(branch) {
    this.removeQueue('/branchs/' + branch.id)
    this.dict_branches.set(`${branch.id}`, branch)
  }

  async getPricesMap() {
    if (this.prices) return this.prices
    try {
      const { data } = await requester.get('/templates', {
        'filter[temp_type]': 'price',
      })
      const { list: templates_list } = data || {}
      this.prices = templates_list.reduce((templates_map, template) => {
        if (template.temp_type !== 'price') return templates_map
        templates_map[template.id] = template
        templates_map[template.id].data.prices = template.data.prices.reduce(
          (template_prices, price) => {
            if (!price.hasOwnProperty('branch_id'))
              return [...template_prices, price]
            return [...template_prices, ...price.prices]
          },
          [],
        )
        return templates_map
      }, {})
    } catch (e) {}
    return this.prices || {}
  }

  @action setSearchIdentity = identity => (this.search_identity = identity)

  async onReadScan() {
    const identity = this.search_identity.trim()
    if (!identity) return
    this.setLoadingCounter()
    try {
      const { data } = await requester.get('/nomenclatures', {
        'filter[identity_search]': identity,
      })
      const items = await Promise.all(
        data.list
          .filter(n => n.identity.split(',').includes(identity))
          .map(n => requester.get('/nomenclatures/' + n.id)),
      )

      const nomenclatures = items.map(i => i?.item).filter(Boolean)

      if (nomenclatures.length === 0)
        return AppStore.showWarning('Номенклатура не найдена')
      for (let i = 0; i < nomenclatures.length; i++) {
        await this.addProduct(nomenclatures[i])
      }
    } finally {
      this.setLoadingCounter(false)
    }
  }

  addProduct = async nomenclature => {
    this.setLoadingCounter()
    try {
      if (!this.item.data || !nomenclature) return
      let prices = await this.getPricesMap()
      let product = {
        uuid: guid(),
        nomenclature_id: nomenclature.id,
        nomenclature: nomenclature,
        total_quantity: null,
        tara_quantity: null,
        tara_weight: null,
        quantity: null,
        price: null,
        identity: null,
        tara_identity: null,
        data: {},
        decider: null,
        prices: [],
        editor: nomenclature.editor || nomenclature.category?.editor || '',
      }
      const { templates } = nomenclature.data
      if (templates?.length > 0 && prices[templates[0]])
        this.setPrices(product, prices[templates[0]].data)
      this.extendProduct(product)
    } finally {
      this.setLoadingCounter(false)
    }
  }

  addDeath = () => {
    let death_item = {
      uuid: guid(),
      rec_date: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      type: 'death',
      death_weight_add_1: null,
      death_weight_add_2: null,
      death_weight_add_3: null,
      death_count_add_1: null,
      death_count_add_2: null,
    }

    const death_items = this.item.data.death_items
    this.item.data.death_items.replace([death_item, ...death_items])
    this.toggleEditor(death_items[0])
  }

  addPallet = () => {
    let pallet_item = {
      uuid: guid(),
      rec_date: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      type: 'pallet',
      weight: null,
      count: null,
    }

    const pallet_items = this.item.data.pallet_items
    this.item.data.pallet_items.replace([pallet_item, ...pallet_items])
    this.toggleEditor(pallet_items[0])
  }

  addTaraSend = () => {
    let item = {
      uuid: guid(),
      rec_date: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      type: 'tara_send',
      count: null,
    }

    const items = this.item.data.tara_send_items
    this.item.data.tara_send_items.replace([item, ...items])
    this.toggleEditor(items[0])
  }

  @action
  extendProduct(product) {
    if (!this.item.data) return
    let p = {
      ...product,
      uuid: guid(),
      rec_date: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
    }
    let products = this.item.data.products
    if (
      products.length > 0 &&
      p.nomenclature_id !== products[products.length - 1].nomenclature_id
    ) {
      return AppStore.showWarning('Нельзя добавлять несколько номенклатур')
    }
    this.item.data.products.replace([p, ...products])
    this.toggleEditor(products[0])
  }

  @action.bound
  copyEditProduct() {
    if (this.edit_product) {
      const product = JSON.parse(JSON.stringify(this.edit_product))
      product.total_quantity = null
      product.quantity = null
      this.extendProduct(product)
    }
  }

  @action.bound
  copyLastProduct() {
    const _product = this.item.data.products[0]
    if (_product) {
      const product = JSON.parse(JSON.stringify(_product))
      product.total_quantity = null
      product.quantity = null
      this.extendProduct(product)
    }
  }

  @action
  toggleEditor(product) {
    this.edit_product = product ? product : null
  }

  @action.bound
  removeEditProduct() {
    const product = this.edit_product
    const edit_type = this.edit_product?.type
    this.toggleEditor()
    const { item } = this
    if (item && item.data) {
      if (edit_type === 'pallet') {
        item.data.pallet_items.remove(product)
      } else if (edit_type === 'death') {
        item.data.death_items.remove(product)
      } else if (edit_type === 'tara_send') {
        item.data.tara_send_items.remove(product)
      } else {
        item.data.products.remove(product)
      }
    }
  }

  product_amount = (item, product) => {
    const { quantity = 0, price = 0 } = product
    return quantity * price
  }

  total_price(product) {
    return this.product_amount(this.item, product)
  }

  @action
  setPrice(product) {
    if (!product.data) return
    if (
      product.prices &&
      product.prices.length > 0 &&
      product.data.price_type
    ) {
      let price = product.prices.find(p => p.name === product.data.price_type)
      if (price) {
        let multiplier = 1
        if (product.decider) {
          multiplier = parseFloat(product.data[product.decider] || '0')
        }
        product.price = parseFloat(
          (parseFloat(price.price) * multiplier).toFixed(4),
        )
      }
      if (isNaN(product.price)) {
        product.price = null
      }
    } else {
      product.price = null
    }
  }

  setProductPrices(product, prices) {
    const { templates } = product.nomenclature.data
    if (!templates || templates.length < 1) return
    if (prices[templates[0]]) this.setPrices(product, prices[templates[0]].data)
  }

  setPrices(product, data) {
    let decider = data.decider || null
    if (decider && !product.data[decider]) {
      product.data[decider] = null
    }
    product.decider = decider
    product.prices = data.prices
  }

  @computed get can_add_product() {
    const { item, access } = this
    return !!item.data && access.includes('edit') && !this.committed
  }

  async fetchMany() {
    if (!this.pathname) return
    let params = {}
    params.page = this.page
    if (this.filtersEnabled) {
      mobx
        .entries(this.filters)
        .filter(BaseStore.validFilters)
        .map(([key, value]) => (params[`filter[${key}]`] = value))
    }
    if (this.order) params.order = this.order
    const { data } = await requester.get(this.pathname, params)
    this.setResult(data)
    return data
  }

  async fetchItem(pathname) {
    this.setLoadingCounter()
    try {
      let { data: response } = await requester.get(pathname, {
        template: this.document_type.template,
      })
      let { item } = response
      if (item.data?.products?.length > 0) {
        let prices = {}
        if (!this.committed && item.data.products.length > 0)
          prices = await this.getPricesMap()
        for (let product of item.data.products) {
          product.uuid = product.uuid || guid()
          product.decider = null
          product.prices = null
          product.identity = product.identity || null
          product.tara_identity = product.tara_identity || null
          product.pid_identity = product.pid_identity || []

          let { nomenclature, nomenclature_id } = product
          if (!nomenclature) {
            const { data } = await requester.get(
              '/nomenclatures/' + nomenclature_id,
            )
            nomenclature = data.item
          }
          product.editor =
            nomenclature.editor || nomenclature.category?.editor || null
          this.setProductEditor(product, nomenclature, product.editor, prices)
        }
      }
      this.setResult(response)
      return response
    } finally {
      this.setLoadingCounter(false)
    }
  }

  setSingle(item) {
    if (!is_my_branch(AppStore.user_info, item.branch_id) || this.committed) {
      super.setAccess(['view'])
    }
    if (!item.data.death_items) item.data.death_items = []
    if (!item.data.pallet_items) item.data.pallet_items = []
    if (!item.data.tara_send_items) item.data.tara_send_items = []
    return super.setSingle(item)
  }

  setProductEditor(product, nomenclature, editor, prices) {
    product.nomenclature = nomenclature
    product.editor = editor
    this.setProductPrices(product, prices)
  }

  @computed
  get products_total_amount() {
    return parseFloat(this.calcTotal(this.item))
  }

  calcTotal(item) {
    const { data, amount } = item
    if (this.committed && !isUndefined(amount)) return amount || 0
    let sum = 0
    if (data)
      sum = data.products.reduce(
        (a, p) => a + this.product_amount(item, p),
        sum,
      )
    return sum.toFixed(4)
  }

  async postData(pathname, item = this.item, ...args) {
    this.setLoadingCounter()
    try {
      for (const p of item.data.products) {
        await this.putFiles(pathname, p)
        await this.putFiles(pathname, p.data)
      }
      const data = await super.postData(
        pathname,
        {
          ...item,
          data: {
            ...item.data,
            pallet_weight: parseFloat(this.pallet_total_weight || '0'),
            death_weight: parseFloat(this.death_total_weight || '0'),
            tara_send: parseFloat(this.total_tara_send_quantity || '0'),
          },
        },
        ...args,
      )
      if (isFinite(parseInt(pathname.split('/')[2]))) {
        await this.fetchItem(pathname)
      }
      return data
    } catch (e) {
      this.catchPostData(e)
      throw e
    } finally {
      this.setLoadingCounter(false)
    }
  }
}

const store = new ProductInvoiceStore()
export default store
