import { inject } from 'mobx-react'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import App from './App'

@withRouter
@inject('store')
export default class Root extends Component {
  constructor(props) {
    super(props)
    this.setScreenSize()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  setScreenSize() {
    const { store } = this.props
    const { innerWidth: width, innerHeight: height } = window
    store.ScreenStore.setScreenDimensions({ width, height })
  }

  componentDidMount() {
    window.addEventListener('resize', this.setScreenSize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenSize.bind(this))
  }

  render() {
    return <App />
  }
}
