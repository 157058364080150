import isUndefined from 'lodash/isUndefined'
import * as mobx from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { is_my_branch, toFilterParams, v2nQ } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'
import IotStore from './IotStore'
import roundQuantity from './production/standard/utils/roundQuantity'

const TRANSFER_SORT_ORDER = {
  draft: 0,
  preinvalid: 1,
  invalid: 2,
  send: 3,
  sent: 4,
  receive: 5,
  received: 6,
}

export class TransferStore extends BaseStore {
  @observable warn_about_quantity = false
  @observable show_scanner = false
  @observable search_identity = ''
  @observable invalid_quantity_keys = observable.array()

  @observable separate_product
  @observable scale_enabled = false
  @observable scale_value
  @observable tara_identity = ''
  @observable selected_product

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get has_separate_product() {
    return !!this.separate_product
  }

  @computed get can_submit_separated_products() {
    return (
      this.has_separate_product && this.separate_product.products.length > 0
    )
  }

  @computed get is_valid_scale() {
    return typeof this.scale_value === 'number' && isFinite(this.scale_value)
  }

  @computed get scale_device_defined() {
    const { branch_id } = this.item
    return !!branch_id && IotStore.branch_devices.has(branch_id)
  }

  @computed get matched_products() {
    const { data } = this.item
    return !data
      ? []
      : this.search_identity.trim()
      ? data.products
          .reduce(this.searchMatches, [])
          .sort(this.sortMatches)
          .map(this.mapMatches)
      : data.products
  }

  @computed get total_quantity() {
    return this.matched_products
      .reduce((sum, p) => sum + parseFloat(p.quantity) || 0, 0)
      .toFixed(3)
  }

  @computed get total_received() {
    return this.matched_products
      .reduce((sum, p) => sum + parseFloat(p.received) || 0, 0)
      .toFixed(3)
  }

  @computed get has_selected_product() {
    return !!this.selected_product
  }

  searchMatches = (filtered, p) => {
    let identity = this.search_identity.trim()
    const arr = identity.split('/')
    if (arr.length > 1) {
      identity = arr[0]
    }
    const ti = p.tara_identity ? p.tara_identity.indexOf(identity) : -1
    const ii = p.identity ? p.identity.indexOf(identity) : -1
    if (ti > -1 || ii > -1) filtered.push([p, ti, ii])
    return filtered
  }

  sortMatches = (a, b) => {
    return a[1] >= 0 && b[1] >= 0
      ? a[1] - b[1]
      : a[1] >= 0
      ? -1
      : b[1] >= 0
      ? 1
      : a[2] >= 0 && b[2] >= 0
      ? a[2] - b[2]
      : a[2] >= 0
      ? -1
      : b[2] >= 0
      ? 1
      : 0
  }

  mapMatches = i => i[0]

  setData(data, count) {
    this._updateBranchesDictionary(data)
    super.setData(data, count)
  }

  @action
  _updateBranchesDictionary(data) {
    if (!this.dict_tables.has('branchs'))
      this.dict_tables.set('branchs', observable.map({}))
    let branches_dict = this.dict_tables.get('branchs')
    for (let item of data) {
      if (
        item.branch_id &&
        item.branch &&
        !branches_dict.has(`${item.branch_id}`)
      ) {
        branches_dict.set(`${item.branch_id}`, item.branch)
      }
      // noinspection JSUnresolvedVariable
      if (
        item.branch2_id &&
        item.branch2 &&
        !branches_dict.has(`${item.branch2_id}`)
      ) {
        // noinspection JSUnresolvedVariable
        branches_dict.set(`${item.branch2_id}`, item.branch2)
      }
    }
  }

  getBranchName(branch_id) {
    const { name } = this.getDictTable('branchs', branch_id)
    return name || ''
  }

  filterDefaults() {
    return { ...super.filterDefaults(), transfer_status: '' }
  }

  setSingle(item) {
    this._validateAccess(item)
    if (item && item.data)
      this._updateNomenclaturesDictionary(item.data.products)
    super.setSingle(item)
  }

  @action
  _validateAccess(item) {
    let access = this.access
    const { transfer_status } = item
    if (['draft', 'invalid', 'send'].includes(transfer_status)) {
      if (access.includes('delete') && !item.id) access.remove('delete')
      if (
        !access.includes('send') &&
        is_my_branch(AppStore.user_info, item.branch_id)
      )
        access.push('send')
    } else if (['sent', 'preinvalid', 'receive'].includes(transfer_status)) {
      access.includes('delete') && access.remove('delete')
      if (
        !access.includes('receive') &&
        is_my_branch(AppStore.user_info, item.branch2_id)
      )
        access.push('receive')
    } else if (transfer_status === 'received') {
      access.replace(['view'])
    }
  }

  @action
  _updateNomenclaturesDictionary(products) {
    if (!this.dict_tables.has('nomenclatures'))
      this.dict_tables.set('nomenclatures', observable.map({}))
    let nomenclatures_dict = this.dict_tables.get('nomenclatures')
    for (let product of products) {
      product.key = this.nextKey()
      if (isUndefined(product.tara_identity)) product.tara_identity = ''
      if (
        product.nomenclature_id &&
        product.nomenclature &&
        !nomenclatures_dict.has(`${product.nomenclature_id}`)
      ) {
        nomenclatures_dict.set(
          `${product.nomenclature_id}`,
          product.nomenclature,
        )
      }
    }
  }

  nextKey() {
    if (isUndefined(this.productsKey) || this.productsKey >= 1e6)
      this.productsKey = 0
    const key = ++this.productsKey
    return `${key}`
  }

  is2Receive(item) {
    const { transfer_status } = item
    if (['sent', 'preinvalid', 'receive', 'received'].includes(transfer_status))
      return is_my_branch(AppStore.user_info, item.branch2_id)
    else if (['draft', 'invalid', 'send'].includes(transfer_status))
      return !is_my_branch(AppStore.user_info, item.branch_id)
    return false
  }

  @action onSelectStockProducts = selected => {
    if (!selected || !this.item.data) return
    if (selected.length > 1) {
      const { products } = this.item.data
      products.replace([...selected.map(this.mapSelected), ...products])
    } else if (selected.length === 1) {
      this.setSeparateProduct(this.mapSelected(selected[0]))
    }
  }

  @action setSeparateProduct = product => {
    this.disableScaleIfDoesntHasDevice()
    this.separate_product = {
      ...product,
      products: [],
    }
    !this.scale_enabled && this.setScaleValue(this.separate_product.quantity)
  }

  mapSelected = ({ nomenclature_id, count }) =>
    this.nextProduct({
      nomenclature_id,
      quantity: parseFloat(count) || null,
    })

  nextProduct = ({ key, ...props }) => ({
    id: null,
    identity: null,
    nomenclature_id: null,
    quantity: null,
    received: null,
    tara_identity: null,
    key: key || this.nextKey(),
    ...props,
  })

  disableScaleIfDoesntHasDevice() {
    this.scale_enabled &&
      this.scale_device_defined &&
      !IotStore.getDevice(this.item.branch_id)?.ip_port &&
      this.disableScale()
  }

  @action clearSeparateProduct = () => {
    this.separate_product = null
    this.tara_identity = ''
    this.show_scanner = false
    this.scale_value = null
  }

  @action enableScale = () => (this.scale_enabled = true)

  @action disableScale = () => (this.scale_enabled = false)

  @action setScaleValue = v =>
    (this.scale_value = v !== null ? roundQuantity(v) : v)

  @action onReadTaraIdentity = ({ data }) => {
    this.hideScanner()
    this.tara_identity = data || ''
  }

  @action addSeparatedProduct = () => {
    if (!this.separate_product || !this.is_valid_scale) return
    const quantity = roundQuantity(this.scale_value)
    this.separate_product.quantity = roundQuantity(
      this.separate_product.quantity - quantity,
    )
    const { products, ...rest } = this.separate_product
    products.unshift({
      ...rest,
      key: this.nextKey(),
      quantity,
      tara_identity: this.tara_identity || null,
    })
    this.setScaleValue(null)
    this.tara_identity = ''
  }

  @action submitSeparatedProducts = () => {
    if (!this.separate_product || !this.item.data) return
    const { products } = this.item.data
    products.replace([...this.separate_product.products, ...products])
    this.clearSeparateProduct()
  }

  @action removeSeparatedProduct = ({ key }) => {
    const i = this.separate_product.products.findIndex(p => p.key === key)
    if (i !== -1) {
      const { quantity } = this.separate_product.products[i]
      this.separate_product.products.splice(i, 1)
      this.separate_product.quantity = roundQuantity(
        this.separate_product.quantity + quantity,
      )
    }
  }

  @action selectProduct = product => {
    this.disableScaleIfDoesntHasDevice()
    this.selected_product = { ...product }
    !this.scale_enabled &&
      this.setScaleValue(
        this.is_sender
          ? this.selected_product.quantity
          : this.selected_product.received,
      )
    this.tara_identity = this.selected_product.tara_identity || ''
  }

  @action clearSelectedProduct = () => {
    this.selected_product = null
    this.tara_identity = ''
    this.show_scanner = false
    this.scale_value = null
  }

  @action submitSelectedProduct = () => {
    if (!this.selected_product || !this.is_valid_scale) return
    const { key } = this.selected_product
    const product = this.item.data.products.find(p => p.key === key)
    const quantity = roundQuantity(this.scale_value)
    if (this.is_sender) product.quantity = quantity
    else product.received = quantity
    product.tara_identity = this.tara_identity || null
    this.tara_identity = ''
    this.clearSelectedProduct()
  }

  @action onReadIdentity = ({ data: identity }) => {
    this.hideScanner()
    const { data } = this.item
    if (!data) return
    const { products } = data
    this.search_identity = identity
    if (this.is_read_only) return
    if (this.is_receiver) {
      this.matched_products.length === 1 &&
        this.selectProduct(this.matched_products[0])
    } else {
      if (localDB.hasConnection) {
        this.searchByIdentity()
      } else {
        products.unshift(
          this.nextProduct({
            identity,
            key: this.nextKey(),
          }),
        )
        this.selectProduct(products[0])
      }
    }
  }

  searchByIdentity = async () => {
    const identity = this.search_identity
    const filters = toFilterParams({
      branch_id: this.item.branch_id,
      categories_transfer: true,
      identity_search: identity,
    })
    const {
      data: { list },
    } = await requester.get('/products/stock', filters)
    if (list.length === 0 || !this.item.data) {
      AppStore.showWarning('Продукт не найден')
    } else if (identity === this.search_identity) {
      this.setSearchByIdentityResult(list)
    }
  }

  @action setSearchByIdentityResult(list) {
    let identity = this.search_identity
    const arr = identity.split('/')
    if (arr.length > 1) {
      identity = arr[0]
    }
    let { products } = this.item.data
    const new_products = list
      .map(item =>
        this.nextProduct({
          key: this.nextKey(),
          id: item.id,
          identity,
          nomenclature_id: item.nomenclature_id,
          quantity: products.reduce((quantity, p) => {
            if (
              item.nomenclature_id === p.nomenclature_id &&
              (item.id ? item.id === p.id : true)
            )
              return quantity - this.toFloat(p.quantity)
            return quantity
          }, parseFloat(item.quantity)),
        }),
      )
      .filter(item => item.quantity > 0)
    if (new_products.length === 1 && arr.length !== 4) {
      this.setSeparateProduct(new_products[0])
    } else {
      products.replace([...new_products, ...products])
    }
  }

  findByIdentity = () =>
    this.search_identity && this.onReadIdentity({ data: this.search_identity })

  @action removeProduct = ({ key }) => {
    const { data } = this.item
    if (this.selected_product) this.clearSelectedProduct()
    if (data) {
      const i = data.products.findIndex(p => p.key === key)
      i !== -1 && data.products.splice(i, 1)
    }
  }

  @action removeSelectedProduct = () =>
    this.removeProduct(this.selected_product)

  isReadOnly() {
    if (super.isReadOnly() || this.warn_about_quantity) return true
    if (['draft', 'invalid', 'send'].includes(this.item.transfer_status))
      return !this.access.includes('send')
    if (['sent', 'preinvalid', 'receive'].includes(this.item.transfer_status))
      return !this.access.includes('receive')
    return true
  }

  @action setTransferStatus(status) {
    this.prev_status = this.item.transfer_status
    this.item.transfer_status = status
  }

  @computed get can_send() {
    const { branch2_id, data } = this.item
    return this.is_sender && !!branch2_id && data && data.products.length > 0
  }

  @computed get is_sender() {
    return this.access.includes('send')
  }

  @computed get can_receive() {
    return (
      this.is_receiver &&
      ['sent', 'preinvalid', 'receive'].includes(this.item.transfer_status)
    )
  }

  @computed get can_invalid() {
    return (
      this.is_receiver &&
      ['sent', 'preinvalid', 'receive'].includes(this.item.transfer_status)
    )
  }

  @computed
  get is_receiver() {
    return this.access.includes('receive')
  }

  static filterQuantityDiff(product) {
    const quantity = parseFloat(product.quantity)
    return (
      !isNaN(product.received) &&
      quantity / 20 < Math.abs(quantity - parseFloat(product.received))
    )
  }

  @computed
  get warn_products() {
    const {
      item: { data },
      is_receiver,
    } = this
    if (!data || !is_receiver) return []
    return data.products.filter(TransferStore.filterQuantityDiff)
  }

  canPrintTransferInvoice() {
    const { item } = this
    return !!(
      item.id &&
      item.branch2_id &&
      item.data &&
      item.data.products.length > 0
    )
  }

  transferInvoicePrintableData() {
    const {
      id,
      branch,
      branch2,
      data: { products },
    } = this.item
    const today = moment()
    let receiver = ''
    if (branch2) {
      receiver = branch2.name
      if (branch2.data) {
        const address = [branch2.data.region, branch2.data.address]
          .filter(i => i)
          .join(', ')
        receiver += address ? `. ${address}` : ''
      }
    }
    return {
      sender: branch.name,
      receiver: receiver,
      by: '________________________________________________________________________________',
      power_of_attorney:
        '________________________________________________________________________________',
      invoice: id,
      account:
        '________________________________________________________________________________',
      date: today.format('«DD» MMMM YYYY'),
      Products: products
        .map(TransferStore.productsInvoice)
        .reduce(TransferStore.reduceInvoiceProducts, []),
      Products2: products
        .map(this.productsCertificate)
        .reduce(TransferStore.reduceProducts, []),
    }
  }

  static productsInvoice(p) {
    const { nomenclature, quantity } = p
    const { name, unit } = nomenclature || {}
    return {
      name: name || '',
      unit: unit ? unit.short || unit.name : '',
      out: '',
      released: v2nQ(quantity),
      released_numeric: quantity,
      price_for_unit: '',
      without_nds: '',
      rate_nds: '',
      amount_nds: '',
    }
  }

  static reduceInvoiceProducts(list, p) {
    if (!p.released_numeric || !parseFloat(p.released_numeric)) return list
    for (let item of list) {
      if (item.name === p.name && item.unit === p.unit) {
        item.released_numeric += parseFloat(p.released_numeric)
        item.released = v2nQ(item.released_numeric)
        return list
      }
    }
    list.push(p)
    return list
  }

  productsCertificate = p => {
    const { nomenclature, quantity, rec_date } = p
    const { name, name_short, tnpa } = nomenclature || {}
    return {
      name: name || '',
      quantity: quantity,
      quantity_numeric: quantity,
      termal_state: '',
      slaughter_date: rec_date ? moment(rec_date).format('DD-MM-YY') : '',
      partition_number:
        (name_short || '') +
        (rec_date ? moment(rec_date).format('DD.MM') : '') +
        '/' +
        this.item.id,
      expiration_date: '16-сут.',
      storage_conditions: '-1°C +4°C',
      control_results: 'реализации без ограничений',
      tnpa: tnpa || '',
      vet_certificate: '',
      invoice: this.item.id,
    }
  }

  static reduceProducts(list, p) {
    if (!p.quantity_numeric || !parseFloat(p.quantity_numeric)) return list
    for (let item of list) {
      if (
        item.name === p.name &&
        item.slaughter_date === p.slaughter_date &&
        item.partition_number === p.partition_number &&
        item.tnpa === p.tnpa
      ) {
        item.quantity_numeric += parseFloat(p.quantity_numeric)
        item.quantity = v2nQ(item.quantity_numeric)
        return list
      }
    }
    list.push(p)
    return list
  }

  @computed get should_warn_about_quantity() {
    return (
      this.is_receiver &&
      !this.warn_about_quantity &&
      this.warn_products.length > 0
    )
  }

  @computed
  get gross_weight_by_scales() {
    return (
      this.item.data.loaded_weight_transport -
      this.item.data.empty_weight_transport
    )
  }

  @computed
  get difference_net() {
    return this.item.data.net_weight_receipt - this.item.data.net_weight_invoice
  }

  @computed
  get difference_net_percent() {
    return (this.difference_net / this.item.data.net_weight_invoice) * 100
  }

  @computed
  get difference_gross() {
    return this.gross_weight_by_scales - this.item.data.gross_weight_receipt
  }

  @computed
  get difference_gross_percent() {
    return (this.difference_gross / this.gross_weight_by_scales) * 100
  }

  @action
  setWarnAboutQuantity(status) {
    this.warn_about_quantity = status
  }

  @action.bound
  clearWarnAboutQuantity() {
    this.warn_about_quantity = false
    if (this.item.transfer_status === 'receive') {
      this.setTransferStatus(this.prev_status || 'sent')
    }
  }

  clearItem() {
    this.selected_product = null
    this.tara_identity = ''
    this.scale_value = null
    this.scale_enabled = false
    this.separate_product = null
    this.invalid_quantity_keys?.clear()
    this.search_identity = ''
    this.setWarnAboutQuantity(false)
    super.clearItem()
  }

  async postData(pathname, item = this.item) {
    if (this.should_warn_about_quantity) {
      this.setWarnAboutQuantity(true)
      throw Error('Подтвердите количество')
    }
    this.warn_about_quantity && this.setWarnAboutQuantity(false)
    try {
      this.item.data.difference_net = this.difference_net
      this.item.data.difference_net_percent = this.difference_net_percent
      this.item.data.difference_gross = this.difference_gross
      this.item.data.difference_gross_percent = this.difference_gross_percent

      const data = await super.postData(pathname, item)
      this.replaceInvalidQuantityKeys([])
      if (
        ['send', 'preinvalid', 'receive'].includes(this.item.transfer_status) &&
        !isNaN(item.id)
      )
        this.fetchItem(pathname)
      return data
    } catch (e) {
      if (e.keys) this.replaceInvalidQuantityKeys(e.keys)
      throw e
    }
  }

  catchPostData(e) {
    if (this.item.transfer_status === 'send') {
      this.setTransferStatus(this.prev_status || 'draft')
    } else if (this.item.transfer_status === 'receive') {
      this.setTransferStatus(this.prev_status || 'sent')
    } else if (this.item.transfer_status === 'preinvalid') {
      this.setTransferStatus(this.prev_status || 'sent')
    }
    if (this.warn_about_quantity) {
      this.setWarnAboutQuantity(false)
    }
    super.catchPostData(e)
  }

  async fetchMany() {
    if (!this.pathname) return
    let params = {}
    params.page = this.page
    this.filtersEnabled &&
      mobx
        .entries(this.filters)
        .filter(BaseStore.validFilters)
        .map(([key, value]) => (params[`filter[${key}]`] = value))
    if (this.order) params.order = this.order
    const data = await localDB.get(
      this.pathname,
      params,
      TransferStore.orderTransfers,
    )
    this.setResult(data)
    return data
  }

  static orderTransfers(t1, t2) {
    return (
      TRANSFER_SORT_ORDER[t1.transfer_status] -
      TRANSFER_SORT_ORDER[t2.transfer_status]
    )
  }

  saveSuccessMessage() {
    const { transfer_status: ts } = this.item
    if (ts === 'send') {
      AppStore.showSuccess('Отправлен')
    } else if (ts === 'preinvalid') {
      AppStore.showSuccess('Отправлен на поправку')
    } else if (ts === 'receive') {
      AppStore.showSuccess('Принят')
    } else {
      super.saveSuccessMessage()
    }
  }

  @action
  fillReceives = () => {
    if (!this.item.data) return
    for (const product of this.item.data.products) {
      if (!product.received && product.received !== 0)
        product.received = parseFloat(product.quantity)
    }
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'branch2_id', 'transfer_status']
  }

  productKey = ({ id, identity, nomenclature_id }) =>
    identity ? identity : id ? 'id:' + id : 'nid:' + nomenclature_id

  @action toggleScanner = () => {
    this.show_scanner = !this.show_scanner
  }

  @action showScanner = () => {
    this.show_scanner = true
  }

  @action hideScanner = () => {
    this.show_scanner = false
  }

  @action replaceInvalidQuantityKeys(keys) {
    this.invalid_quantity_keys.replace(keys)
  }

  toFloat(value) {
    const parsedValue = typeof value !== 'number' ? parseFloat(value) : value
    return isFinite(parsedValue) ? parsedValue : 0
  }

  @computed get transfer_products_reduced() {
    if (!this.item.data) return []
    let nomenclature_products = this.item.data.products.reduce(
      this.reduceProducts,
      {},
    )
    return Object.values(nomenclature_products).map(this.mapReduced)
  }

  reduceProducts = (products, product) => {
    const { nomenclature_id, identity, quantity, received } = product
    if (!products[nomenclature_id]) {
      let identities = new Set((identity && [identity]) || [])
      products[nomenclature_id] = {
        nomenclature_id,
        count: 1,
        identities,
        quantity: this.toFloat(quantity),
        received: this.toFloat(received),
      }
    } else {
      products[nomenclature_id].count++
      products[nomenclature_id].quantity += this.toFloat(quantity)
      products[nomenclature_id].received += this.toFloat(received)
      if (identity) {
        !products[nomenclature_id].identities.has(identity) &&
          products[nomenclature_id].identities.add(identity)
      }
    }
    return products
  }

  mapReduced = item => ({
    ...item,
    part_count: item.identities.size,
    quantity: roundQuantity(item.quantity),
    received: roundQuantity(item.received),
  })

  goBack() {
    if (this.warn_about_quantity) this.clearWarnAboutQuantity()
    if (this.separate_product) this.clearSeparateProduct()
    else return false
  }

  @computed get can_split() {
    let can_split =
      !this.warn_about_quantity &&
      this.is_receiver &&
      this.item.transfer_status === 'sent'
    can_split =
      can_split &&
      this.item.data.products.some(
        product =>
          product.received !== null && isFinite(parseFloat(product.received)),
      )
    can_split =
      can_split &&
      this.item.data.products.some(
        product =>
          product.received === null || !isFinite(parseFloat(product.received)),
      )
    return can_split
  }

  @computed get can_fill_all() {
    let can_fill_all =
      !this.warn_about_quantity &&
      this.is_receiver &&
      this.item.transfer_status === 'sent'
    can_fill_all =
      can_fill_all &&
      this.item.data.products.some(
        product => product.received === null || product.received === '',
      )
    return can_fill_all
  }

  @action fillAll = () => {
    this.can_fill_all &&
      this.item.data.products.forEach(product => {
        if (product.received === null || product.received === '') {
          product.received = product.quantity
        }
      })
  }

  async postSplitData(item_id) {
    const { data } = await requester.post('/products/transfer/split/' + item_id)
    return data
  }
}

const store = new TransferStore()
export default store
